import { bitcoinUtils } from "@teleportdao/bitcoin";
import config from "config/config.json";
import { ethers } from "ethers";
import { NetworkTypeDTO } from "models/DTO";

const networks: { [x: string]: string } = config.networks;
const subgraph = config.subgraph;
const contractAddress: any = config.contractAddress;
const transactionBTCEndpoint = config.transactionBTCEndpoint;
const intervalDataUpdate = 5 * 60 * 1000;
const intervalAutoUpdateDataUpdate = 2 * 60 * 1000;
const normalConfirm = 3 * 10; // 40min
const lowBurnConfirm = 2; //2min
const normalBurnConfirm = 7; //7min

const sourceNetwork = config.sourceNetwork;
const targetNetworkConnectionInfo: any = config.targetNetworkConnectionInfo;
const chainId: any = config.chainId;
const appId = config.appId;
const appIdBrc20Transfer = config.appIdBrc20Transfer;
const appIdBrc20Exchange = config.appIdBrc20Exchange;

const appIdRuneTransfer = 0; //TODO v3
const appIdRuneExchange = 1; //TODO v3

const quickSwapV2AppId = config.quickSwapV2AppId;
const quickSwapV3AppId = config.quickSwapV3AppId;
const iZiSwapAppId = 20;
const _chains = config.chains;
const JsonRpcProviderUrl: any = config.JsonRpcProviderUrl;
const JsonRpcProviderOption = {
    Polygon: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["Polygon"]),
    BNB: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["BNB"]),
    Ethereum: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["Ethereum"]),
    Arbitrum: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["Arbitrum"]),
    Optimism: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["Optimism"]),
    BSquared: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["BSquared"]),
    BOB: new ethers.providers.StaticJsonRpcProvider(JsonRpcProviderUrl["BOB"]),
};
const baseAPI = config.baseAPI;
const mempoolAPI = config.mempoolAPI;
const projectName = config.projectName;
const txEndpoint: any = config.txEndpoint;
const unisatWalletLink = "https://chrome.google.com/webstore/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo";
const xverseWalletLink =
    "https://chrome.google.com/webstore/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg?hl=en-GB&authuser=1";
const okxWalletLink = "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge";
const phantomWalletLink = "https://phantom.app/";
const leatherWalletLink = "https://leather.io/install-extension";
const xdefiWalletLink = "https://go.xdefi.io/teleportdao";

const isTestnet = sourceNetwork.name.includes("test");
const tokensList: any = {
    Polygon: isTestnet
        ? [
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.teleBTCAddress?.toLowerCase(),
                  symbol: "TELEBTC",
                  icon: "TELEBTC",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              //   {
              //       address: contractAddress?.[NetworkTypeDTO.polygon]?.USDTAddress?.toLowerCase(),
              //       symbol: "USDT",
              //       icon: "USDT",
              //       decimal: 6,
              //       symbolPrice: "USDT",
              //   },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WMATIC",
                  icon: "wmatic",
                  decimal: 18,
                  symbolPrice: "WMATIC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "MATIC",
                  icon: "MATIC",
                  decimal: 18,
                  symbolPrice: "MATIC",
              },
          ]
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.teleBTCAddress?.toLowerCase(),
                  symbol: "TELEBTC",
                  icon: "TELEBTC",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.USDCEAddress?.toLowerCase(),
                  symbol: "USDC.e",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 6,
                  symbolPrice: "USDT",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.WBTCAddress?.toLowerCase(),
                  symbol: "WBTC",
                  icon: "wbtc",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WMATIC",
                  icon: "wmatic",
                  decimal: 18,
                  symbolPrice: "WMATIC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "MATIC",
                  icon: "MATIC",
                  decimal: 18,
                  symbolPrice: "MATIC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.WETHAddress?.toLowerCase(),
                  symbol: "WETH",
                  icon: "weth",
                  decimal: 18,
                  symbolPrice: "ETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
                  hidden: true,
              },
          ],
    BNB: isTestnet
        ? [
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.teleBTCAddress?.toLowerCase(),
                  symbol: "TELEBTC",
                  icon: "BTC",
                  decimal: 8,
                  symbolPrice: "BTC",
                  hidden: true,
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 18,
                  symbolPrice: "USDC",
                  hidden: true,
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 18,
                  symbolPrice: "USDT",
                  hidden: true,
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WBNB",
                  icon: "bnb",
                  decimal: 18,
                  symbolPrice: "WBNB",
                  hidden: true,
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "BNB",
                  icon: "bnb",
                  decimal: 18,
                  symbolPrice: "BNB",
                  hidden: true,
              },
          ]
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.teleBTCAddress?.toLowerCase(),
                  symbol: "TELEBTC",
                  icon: "TELEBTC",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 18,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 18,
                  symbolPrice: "USDT",
              },

              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WBNB",
                  icon: "bnb",
                  decimal: 18,
                  symbolPrice: "WBNB",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "BNB",
                  icon: "bnb",
                  decimal: 18,
                  symbolPrice: "BNB",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.BTCBAddress?.toLowerCase(),
                  symbol: "BTCB",
                  icon: "BTC",
                  decimal: 18,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.binance]?.ETHAddress?.toLowerCase(),
                  symbol: "ETH",
                  icon: "eth",
                  decimal: 18,
                  symbolPrice: "ETH",
              },
          ],
    Ethereum: isTestnet
        ? [
              {
                  address: contractAddress?.[NetworkTypeDTO.ethereum]?.MATICAddress?.toLowerCase(),
                  symbol: "MATIC",
                  icon: "MATIC",
                  decimal: 18,
                  symbolPrice: "MATIC",
              },
          ]
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.ethereum]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.ethereum]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 6,
                  symbolPrice: "USDT",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.ethereum]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WETH",
                  icon: "weth",
                  decimal: 18,
                  symbolPrice: "WETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.ethereum]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "ETH",
                  icon: "eth",
                  decimal: 18,
                  symbolPrice: "ETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.ethereum]?.WBTCAddress?.toLowerCase(),
                  symbol: "WBTC",
                  icon: "wbtc",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              //   {
              //       address: contractAddress?.[NetworkTypeDTO.ethereum]?.MATICAddress?.toLowerCase(),
              //       symbol: "MATIC",
              //       icon: "MATIC",
              //       decimal: 18,
              //       symbolPrice: "MATIC",
              //   },
          ],
    Arbitrum: isTestnet
        ? []
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.arbitrum]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.arbitrum]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 6,
                  symbolPrice: "USDT",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.arbitrum]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WETH",
                  icon: "weth",
                  decimal: 18,
                  symbolPrice: "WETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.arbitrum]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "ETH",
                  icon: "eth",
                  decimal: 18,
                  symbolPrice: "ETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.arbitrum]?.WBTCAddress?.toLowerCase(),
                  symbol: "WBTC",
                  icon: "wbtc",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              //   {
              //       address: contractAddress?.[NetworkTypeDTO.arbitrum]?.MATICAddress?.toLowerCase(),
              //       symbol: "MATIC",
              //       icon: "MATIC",
              //       decimal: 18,
              //       symbolPrice: "MATIC",
              //   },
          ],
    Optimism: isTestnet
        ? []
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.optimism]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.optimism]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 6,
                  symbolPrice: "USDT",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.optimism]?.WBTCAddress?.toLowerCase(),
                  symbol: "WBTC",
                  icon: "wbtc",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.optimism]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WETH",
                  icon: "weth",
                  decimal: 18,
                  symbolPrice: "WETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.optimism]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "ETH",
                  icon: "eth",
                  decimal: 18,
                  symbolPrice: "ETH",
              },

              //   {
              //       address: contractAddress?.[NetworkTypeDTO.optimism]?.MATICAddress?.toLowerCase(),
              //       symbol: "MATIC",
              //       icon: "MATIC",
              //       decimal: 18,
              //       symbolPrice: "MATIC",
              //   },
          ],
    BSquared: isTestnet
        ? []
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.bsquared]?.teleBTCAddress?.toLowerCase(),
                  symbol: "TELEBTC",
                  icon: "TELEBTC",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bsquared]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bsquared]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 6,
                  symbolPrice: "USDT",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bsquared]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WBTC",
                  icon: "wbtc",
                  decimal: 18,
                  symbolPrice: "WBTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bsquared]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "BTC",
                  icon: "BTC",
                  decimal: 18,
                  symbolPrice: "BTC",
              },
          ],
    BOB: isTestnet
        ? []
        : [
              {
                  address: contractAddress?.[NetworkTypeDTO.bob]?.teleBTCAddress?.toLowerCase(),
                  symbol: "TELEBTC",
                  icon: "TELEBTC",
                  decimal: 8,
                  symbolPrice: "BTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bob]?.USDCAddress?.toLowerCase(),
                  symbol: "USDC",
                  icon: "USDC",
                  decimal: 6,
                  symbolPrice: "USDC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bob]?.USDTAddress?.toLowerCase(),
                  symbol: "USDT",
                  icon: "USDT",
                  decimal: 6,
                  symbolPrice: "USDT",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bob]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "WETH",
                  icon: "weth",
                  decimal: 18,
                  symbolPrice: "WETH",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bob]?.WBTCAddress?.toLowerCase(),
                  symbol: "WBTC",
                  icon: "wbtc",
                  decimal: 8,
                  symbolPrice: "WBTC",
              },
              {
                  address: contractAddress?.[NetworkTypeDTO.bob]?.WNATIVEAddress?.toLowerCase(),
                  symbol: "ETH",
                  icon: "eth",
                  decimal: 18,
                  symbolPrice: "ETH",
              },
          ],
};
const tokensListFarming = {
    Polygon: [
        {
            key: "WBTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.polygon]?.WBTCAddress?.toLowerCase(),
                symbol: "WBTC",
                icon: "wbtc",
                decimal: 8,
                symbolPrice: "BTC",
            },
            icon: "wbtc",
        },
    ],
    BNB: [
        {
            key: "BTCB",
            value: {
                address: contractAddress?.[NetworkTypeDTO.binance]?.BTCBAddress?.toLowerCase(),
                symbol: "BTCB",
                icon: "BTC",
                decimal: 18,
                symbolPrice: "BTC",
            },
            icon: "btc",
        },
    ],
    Ethereum: isTestnet
        ? []
        : [
              {
                  key: "ETH",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.ethereum]?.WNATIVEAddress?.toLowerCase(),
                      symbol: "WETH",
                      icon: "weth",
                      decimal: 18,
                      symbolPrice: "ETH",
                  },
                  icon: "eth",
              },
          ],
    Arbitrum: isTestnet
        ? []
        : [
              {
                  key: "ETH",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.arbitrum]?.WNATIVEAddress?.toLowerCase(),
                      symbol: "WETH",
                      icon: "weth",
                      decimal: 18,
                      symbolPrice: "ETH",
                  },
                  icon: "eth",
              },
          ],
    Optimism: isTestnet
        ? []
        : [
              {
                  key: "ETH",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.optimism]?.WNATIVEAddress?.toLowerCase(),
                      symbol: "WETH",
                      icon: "weth",
                      decimal: 18,
                      symbolPrice: "ETH",
                  },
                  icon: "eth",
              },
          ],
    BSquared: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.bsquared]?.WNATIVEAddress?.toLowerCase(),
                      symbol: "WBTC",
                      icon: "wbtc",
                      decimal: 18,
                      symbolPrice: "WBTC",
                  },
                  icon: "wbtc",
              },
          ],
    BOB: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.bob]?.WBTCAddress?.toLowerCase(),
                      symbol: "WBTC",
                      icon: "wbtc",
                      decimal: 8,
                      symbolPrice: "WBTC",
                  },
                  icon: "wbtc",
              },
          ],
};
const lpTokens = {
    BNB: isTestnet
        ? [
              {
                  name: "TELEBTC-WBNB",
                  address: contractAddress?.[NetworkTypeDTO.binance]?.lpNativeTokenAddress, //V2
                  price_high: 0,
                  price_low: 0,
              },
          ]
        : [
              {
                  name: "TELEBTC-BTCB",
                  address: contractAddress?.[NetworkTypeDTO.binance]?.lpBTCBTokenAddressV3, //V3
                  price_high: 1 / 1.01816,
                  price_low: 1 / 0.983142,
              },
              {
                  name: "TELEBTC-ORDI",
                  address: contractAddress?.[NetworkTypeDTO.binance]?.lpOrdiTokenAddressV3, //V3
                  price_high: 1 / 4106.4,
                  price_low: 1 / 1035.51,
              },
              {
                  name: "TELEBTC-SATS",
                  address: contractAddress?.[NetworkTypeDTO.binance]?.lpSatsTokenAddressV3, //V3
                  price_high: 0,
                  price_low: 0,
              },
              //   {
              //       name: "TELEBTC-MEME",
              //       address: contractAddress?.[NetworkTypeDTO.binance]?.lpMemeTokenAddress,  //V2
              //   },
              //   {
              //       name: "TELEBTC-.COM",
              //       address: contractAddress?.[NetworkTypeDTO.binance]?.lpComTokenAddress,  //V2
              //   },
              //   {
              //       name: "TELEBTC-TRIO",
              //       address: contractAddress?.[NetworkTypeDTO.binance]?.lpTrioTokenAddress,  //V2
              //   },
          ],
    Polygon: isTestnet
        ? [
              {
                  name: "TELEBTC-WMATIC",
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.lpNativeTokenAddress, //V2
                  price_high: 0,
                  price_low: 0,
              },
          ]
        : [
              {
                  name: "TELEBTC-WBTC",
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.lpNativeTokenAddressV3, //V3
                  price_high: 1.9936468,
                  price_low: 0.49561052,
              },
              {
                  name: "TELEBTC-DOG",
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.lpDOGTokenAddressV3, //V3
                  price_high: 0.000000135092,
                  price_low: 0.000000033785284,
              },
              {
                  name: "TELEBTC-PUPS",
                  address: contractAddress?.[NetworkTypeDTO.polygon]?.lpPUPSTokenAddressV3, //V3
                  price_high: 0.0000044421077,
                  price_low: 0.0000011109308,
              },
          ],
    Arbitrum: [],
    Ethereum: [],
    Optimism: [],
    BSquared: isTestnet
        ? []
        : [
              {
                  name: "TELEBTC-WBTC",
                  address: contractAddress?.[NetworkTypeDTO.bsquared]?.lpNativeTokenAddress, //V3
                  price_high: 1.01816,
                  price_low: 0.983143,
              },
          ],
    BOB: isTestnet
        ? []
        : [
              {
                  name: "TELEBTC-WBTC",
                  address: contractAddress?.[NetworkTypeDTO.bob]?.lpNativeTokenAddress, //V3
                  price_high: 0,
                  price_low: 0,
              },
          ],
};

const dexItems = [
    { id: quickSwapV2AppId, name: "QuickSwapV2" },
    { id: quickSwapV3AppId, name: "QuickSwapV3" },
    { id: iZiSwapAppId, name: "iZiSwap" },
];

const chains = isTestnet
    ? [
          {
              id: `0x${Number(_chains[0].id).toString(16)}`,
              token: _chains[0].token,
              label: _chains[0].label,
              rpcUrl: _chains[0].rpcUrl,
          },
          {
              id: `0x${Number(_chains[1].id).toString(16)}`,
              token: _chains[1].token,
              label: _chains[1].label,
              rpcUrl: _chains[1].rpcUrl,
          },
          {
              id: `0x${Number(_chains[2].id).toString(16)}`,
              token: _chains[2].token,
              label: _chains[2].label,
              rpcUrl: _chains[2].rpcUrl,
          },
      ]
    : [
          {
              id: `0x${Number(_chains[0].id).toString(16)}`,
              token: _chains[0].token,
              label: _chains[0].label,
              rpcUrl: _chains[0].rpcUrl,
          },
          {
              id: `0x${Number(_chains[1].id).toString(16)}`,
              token: _chains[1].token,
              label: _chains[1].label,
              rpcUrl: _chains[1].rpcUrl,
          },
          {
              id: `0x${Number(_chains[2].id).toString(16)}`,
              token: _chains[2].token,
              label: _chains[2].label,
              rpcUrl: _chains[2].rpcUrl,
          },
          {
              id: `0x${Number(_chains[3].id).toString(16)}`,
              token: _chains[3].token,
              label: _chains[3].label,
              rpcUrl: _chains[3].rpcUrl,
          },
          {
              id: `0x${Number(_chains[4].id).toString(16)}`,
              token: _chains[4].token,
              label: _chains[4].label,
              rpcUrl: _chains[4].rpcUrl,
          },
          {
              id: `0x${Number(_chains[5].id).toString(16)}`,
              token: _chains[5].token,
              label: _chains[5].label,
              rpcUrl: _chains[5].rpcUrl,
          },
          {
              id: `0x${Number(_chains[6].id).toString(16)}`,
              token: _chains[6].token,
              label: _chains[6].label,
              rpcUrl: _chains[6].rpcUrl,
          },
      ];
const chainsDetects: any = isTestnet
    ? [
          {
              id: `0x${Number(_chains[0].id).toString(16)}`,
              chainId: _chains[0].id,
              chainNetwork: _chains[0].chainNetwork,
              token: _chains[0].token,
          },
          {
              id: `0x${Number(_chains[1].id).toString(16)}`,
              chainId: _chains[1].id,
              chainNetwork: _chains[1].chainNetwork,
              token: _chains[1].token,
          },
          {
              id: `0x${Number(_chains[2].id).toString(16)}`,
              chainId: _chains[2].id,
              chainNetwork: _chains[2].chainNetwork,
              token: _chains[2].token,
          },
      ]
    : [
          {
              id: `0x${Number(_chains[0].id).toString(16)}`,
              chainId: _chains[0].id,
              chainNetwork: _chains[0].chainNetwork,
              token: _chains[0].token,
          },
          {
              id: `0x${Number(_chains[1].id).toString(16)}`,
              chainId: _chains[1].id,
              chainNetwork: _chains[1].chainNetwork,
              token: _chains[1].token,
          },
          {
              id: `0x${Number(_chains[2].id).toString(16)}`,
              chainId: _chains[2].id,
              chainNetwork: _chains[2].chainNetwork,
              token: _chains[2].token,
          },
          {
              id: `0x${Number(_chains[3].id).toString(16)}`,
              chainId: _chains[3].id,
              chainNetwork: _chains[3].chainNetwork,
              token: _chains[3].token,
          },
          {
              id: `0x${Number(_chains[4].id).toString(16)}`,
              chainId: _chains[4].id,
              chainNetwork: _chains[4].chainNetwork,
              token: _chains[4].token,
          },
          {
              id: `0x${Number(_chains[5].id).toString(16)}`,
              chainId: _chains[5].id,
              chainNetwork: _chains[5].chainNetwork,
              token: _chains[5].token,
          },
          {
              id: `0x${Number(_chains[6].id).toString(16)}`,
              chainId: _chains[6].id,
              chainNetwork: _chains[6].chainNetwork,
              token: _chains[6].token,
          },
      ];
const dAppConfigMetaData = {
    name: "Teleswap",
    description: "Teleswap dApp",
    gettingStartedGuide: "https://teleswap.xyz/",
    explore: "https://teleswap.xyz/",
    version: "1.0.0",
    aboutUrl: "https://blog.teleportdao.xyz/what-is-teleswap-81d58d303e8",
    termsUrl: "https://teleswap.xyz/terms-conditions",
    privacyUrl: "https://www.teleportdao.xyz/",
    youtubeUrl: "http://teleswap.xyz/",
    twitterUrl: "https://twitter.com/teleport_DAO",
    facebookUrl: "http://teleswap.xyz/",
    discordUrl: "https://discord.gg/2mYJBB9bGR",
    mailUrl: "mailto:info@teleportdao.xyz",
    instagramUrl: "http://teleswap.xyz/",
    faqsUrl: "https://leaderboard.teleportdao.xyz/support?tab=teleswap",
    docUrl: "https://docs.teleswap.xyz/",
    tstUrl: "https://coinmarketcap.com/currencies/teleport-system-token/",
    blogUrl: "https://blog.teleportdao.xyz/",
};

const bip32Prefix = {
    xprv: "0x0488ade4", // Mainnet - P2PKH or P2SH  - m/44'/0'
    yprv: "0x049d7878", // Mainnet - P2WPKH in P2SH - m/49'/0'
    zprv: "0x04b2430c", // Mainnet - P2WPKH 	     - m/84'/0'
    Yprv: "0x0295b005", // Mainnet - Multi-signature P2WSH in P2SH
    Zprv: "0x02aa7a99", // Mainnet - Multi-signature P2WSH
    tprv: "0x04358394", // Testnet - P2PKH or P2SH  - m/44'/1'
    uprv: "0x044a4e28", // Testnet - P2WPKH in P2SH - m/49'/1'
    vprv: "0x045f18bc", // Testnet - P2WPKH         - m/84'/1'
    Uprv: "0x024285b5", // Testnet - Multi-signature P2WSH in P2SH
    Vprv: "0x02575048", // Testnet - Multi-signature P2WSH

    xpub: "0x0488b21e", // Mainnet - P2PKH or P2SH  - m/44'/0'
    ypub: "0x049d7cb2", // Mainnet - P2WPKH in P2SH - m/49'/0'
    zpub: "0x04b24746", // Mainnet - P2WPKH 	     - m/84'/0'
    Ypub: "0x0295b43f", // Mainnet - Multi-signature P2WSH in P2SH
    Zpub: "0x02aa7ed3", // Mainnet - Multi-signature P2WSH
    tpub: "0x043587cf", // Testnet - P2PKH or P2SH  - m/44'/1'
    upub: "0x044a5262", // Testnet - P2WPKH in P2SH - m/49'/1'
    vpub: "0x045f1cf6", // Testnet - P2WPKH         - m/84'/1'
    Upub: "0x024289ef", // Testnet - Multi-signature P2WSH in P2SH
    Vpub: "0x02575483", // Testnet - Multi-signature P2WSH
};
const snapType: any = {
    p2sh: {
        sType: "P2SH-P2WPKH",
        type: "p2sh",
        derivationPathTestnet: `m/49'/1'/0'/0/0`,
        derivationPathMainnet: `m/49'/0'/0'/0/0`,
    },
    p2pkh: {
        sType: "P2PKH",
        type: "p2pkh",
        derivationPathTestnet: `m/44'/1'/0'/0/0`,
        derivationPathMainnet: `m/44'/0'/0'/0/0`,
    },
    p2wpkh: {
        sType: "P2WPKH",
        type: "p2wpkh",
        derivationPathTestnet: `m/84'/1'/0'/0/0`,
        derivationPathMainnet: `m/84'/0'/0'/0/0`,
    },
    p2tr: {
        sType: "P2TR",
        type: "p2tr",
        derivationPathTestnet: `m/86'/1'/0'/0/0`,
        derivationPathMainnet: `m/86'/0'/0'/0/0`,
    },
};
const bip32NetworkConfig: any = isTestnet
    ? {
          p2pkh: {
              ...bitcoinUtils.networks.testnet,
              bip32: {
                  private: +bip32Prefix.tprv,
                  public: +bip32Prefix.tpub,
              },
          },
          p2sh: {
              ...bitcoinUtils.networks.testnet,
              bip32: {
                  private: +bip32Prefix.uprv,
                  public: +bip32Prefix.upub,
              },
          },
          p2wpkh: {
              ...bitcoinUtils.networks.testnet,
              bip32: {
                  private: +bip32Prefix.vprv,
                  public: +bip32Prefix.vpub,
              },
          },
          p2tr: {
              ...bitcoinUtils.networks.testnet,
              bip32: {
                  private: +bip32Prefix.tprv,
                  public: +bip32Prefix.tpub,
              },
          },
      }
    : {
          p2pkh: {
              ...bitcoinUtils.networks.bitcoin,
              bip32: {
                  private: +bip32Prefix.xprv,
                  public: +bip32Prefix.xpub,
              },
          },
          p2sh: {
              ...bitcoinUtils.networks.bitcoin,
              bip32: {
                  private: +bip32Prefix.yprv,
                  public: +bip32Prefix.ypub,
              },
          },
          p2wpkh: {
              ...bitcoinUtils.networks.bitcoin,
              bip32: {
                  private: +bip32Prefix.zprv,
                  public: +bip32Prefix.zpub,
              },
          },
          p2tr: {
              ...bitcoinUtils.networks.bitcoin,
              bip32: {
                  private: +bip32Prefix.xprv,
                  public: +bip32Prefix.xpub,
              },
          },
      };

const networkColors: any = {
    Polygon: "text-purple-500",
    BNB: "text-yellow",
    Ethereum: "text-cyan-700",
    Arbitrum: "text-blue-500",
    Optimism: "text-red-500",
    BSquared: "text-orange-300",
    Bitcoin: "text-orange-400",
    BOB: "text-orange-500",
};
const networkBgColors: any = {
    Polygon: "bg-purple-500",
    BNB: "bg-yellow",
    Ethereum: "bg-cyan-700",
    Arbitrum: "bg-blue-500",
    Optimism: "bg-red-500",
    BSquared: "bg-orange-300",
    Bitcoin: "bg-orange-400",
    BOB: "bg-orange-500",
};
const networkChainsId: any = isTestnet
    ? {
          Polygon: `0x${Number(_chains[0].id).toString(16)}`,
          BNB: `0x${Number(_chains[1].id).toString(16)}`,
          Ethereum: `0x${Number(_chains[2].id).toString(16)}`,
      }
    : {
          Polygon: `0x${Number(_chains[0].id).toString(16)}`,
          BNB: `0x${Number(_chains[1].id).toString(16)}`,
          Ethereum: `0x${Number(_chains[2].id).toString(16)}`,
          Arbitrum: `0x${Number(_chains[3].id).toString(16)}`,
          Optimism: `0x${Number(_chains[4].id).toString(16)}`,
          BSquared: `0x${Number(_chains[5].id).toString(16)}`,
          BOB: `0x${Number(_chains[6].id).toString(16)}`,
      };

const convertNetworkType: any = {
    Polygon: "polygon",
    BNB: "bsc",
    Ethereum: "ethereum",
    Arbitrum: "arbitrum",
    Optimism: "optimism",
    BSquared: "bsquared",
    BOB: "bob",
};

const slippageList: any = [
    { key: "1", value: 1 },
    { key: "3", value: 3 },
    { key: "5", value: 5 },
];
const speedTypeList: any = [
    { key: "slow", value: "Low" },
    { key: "normal", value: "Medium" },
    { key: "fast", value: "High" },
];

const dexList: any = dexItems?.map((i: any) => {
    return {
        key: i.name,
        value: { address: i.id, symbol: i.name },
        icon: "quick",
    };
});
const expectedWaitingTime: any = {
    normal: `${normalConfirm * 10} mins`,
    instant: `15 seconds`,
};

const exchangeTokenOptions: any = {
    Polygon: [
        {
            key: "BTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.polygon].teleBTCAddress?.toLowerCase(),
                symbol: "BTC",
                decimal: 8,
                icon: "BTC",
                symbolPrice: "BTC",
                type: "btc",
                network: "Bitcoin",
            },
            icon: "BTC",
        },
        ...tokensList?.[NetworkTypeDTO.polygon]
            ?.filter((i: any) => !i.hidden)
            ?.map((i: any) => {
                return {
                    key: i.symbol,
                    value: { ...i, type: "evm", network: NetworkTypeDTO.polygon },
                    icon: i.icon,
                };
            }),
    ],
    BNB: [
        {
            key: "BTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.binance].teleBTCAddress?.toLowerCase(),
                symbol: "BTC",
                decimal: 8,
                icon: "BTC",
                symbolPrice: "BTC",
                type: "btc",
                network: "Bitcoin",
            },
            icon: "BTC",
        },
        ...tokensList?.[NetworkTypeDTO.binance]
            ?.filter((i: any) => !i.hidden)
            ?.map((i: any) => {
                return {
                    key: i.symbol,
                    value: { ...i, type: "evm", network: NetworkTypeDTO.binance },
                    icon: i.icon,
                };
            }),
    ],
    Ethereum: [
        {
            key: "BTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.ethereum].teleBTCAddress?.toLowerCase(),
                symbol: "BTC",
                decimal: 8,
                icon: "BTC",
                symbolPrice: "BTC",
                type: "btc",
                network: "Bitcoin",
            },
            icon: "BTC",
        },
        ...tokensList?.[NetworkTypeDTO.ethereum]?.map((i: any) => {
            return {
                key: i.symbol,
                value: { ...i, type: "evm", network: NetworkTypeDTO.ethereum },
                icon: i.icon,
            };
        }),
    ],
    Arbitrum: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.arbitrum].teleBTCAddress?.toLowerCase(),
                      symbol: "BTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                      type: "btc",
                      network: "Bitcoin",
                  },
                  icon: "BTC",
              },
              ...tokensList?.[NetworkTypeDTO.arbitrum]?.map((i: any) => {
                  return {
                      key: i.symbol,
                      value: { ...i, type: "evm", network: NetworkTypeDTO.arbitrum },
                      icon: i.icon,
                  };
              }),
          ],
    Optimism: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.optimism].teleBTCAddress?.toLowerCase(),
                      symbol: "BTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                      type: "btc",
                      network: "Bitcoin",
                  },
                  icon: "BTC",
              },
              ...tokensList?.[NetworkTypeDTO.optimism]?.map((i: any) => {
                  return {
                      key: i.symbol,
                      value: { ...i, type: "evm", network: NetworkTypeDTO.optimism },
                      icon: i.icon,
                  };
              }),
          ],

    BSquared: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.bsquared].teleBTCAddress?.toLowerCase(),
                      symbol: "BTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                      type: "btc",
                      network: "Bitcoin",
                  },
                  icon: "BTC",
              },
              ...tokensList?.[NetworkTypeDTO.bsquared]?.map((i: any) => {
                  return {
                      key: i.symbol,
                      value: { ...i, type: "evm", network: NetworkTypeDTO.bsquared },
                      icon: i.icon,
                  };
              }),
          ],
    BOB: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.bob].teleBTCAddress?.toLowerCase(),
                      symbol: "BTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                      type: "btc",
                      network: "Bitcoin",
                  },
                  icon: "BTC",
              },
              ...tokensList?.[NetworkTypeDTO.bob]?.map((i: any) => {
                  return {
                      key: i.symbol,
                      value: { ...i, type: "evm", network: NetworkTypeDTO.bob },
                      icon: i.icon,
                  };
              }),
          ],
};

const tokenOptionsTELEBTC: any = {
    Polygon: [
        {
            key: "BTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.polygon].teleBTCAddress?.toLowerCase(),
                symbol: "TELEBTC",
                decimal: 8,
                icon: "BTC",
                symbolPrice: "BTC",
            },
            icon: "BTC",
        },
    ],
    BNB: [
        {
            key: "BTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.binance].teleBTCAddress?.toLowerCase(),
                symbol: "TELEBTC",
                decimal: 8,
                icon: "BTC",
                symbolPrice: "BTC",
            },
            icon: "BTC",
        },
    ],
    Ethereum: [
        {
            key: "BTC",
            value: {
                address: contractAddress?.[NetworkTypeDTO.ethereum].teleBTCAddress?.toLowerCase(),
                symbol: "TELEBTC",
                decimal: 8,
                icon: "BTC",
                symbolPrice: "BTC",
            },
            icon: "BTC",
        },
    ],
    Arbitrum: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.arbitrum].teleBTCAddress?.toLowerCase(),
                      symbol: "TELEBTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                  },
                  icon: "BTC",
              },
          ],
    Optimism: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.optimism].teleBTCAddress?.toLowerCase(),
                      symbol: "TELEBTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                  },
                  icon: "BTC",
              },
          ],
    BSquared: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.bsquared].teleBTCAddress?.toLowerCase(),
                      symbol: "TELEBTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                  },
                  icon: "BTC",
              },
          ],
    BOB: isTestnet
        ? []
        : [
              {
                  key: "BTC",
                  value: {
                      address: contractAddress?.[NetworkTypeDTO.bob].teleBTCAddress?.toLowerCase(),
                      symbol: "TELEBTC",
                      decimal: 8,
                      icon: "BTC",
                      symbolPrice: "BTC",
                  },
                  icon: "BTC",
              },
          ],
};

const tokenOptionsWTST: any = {
    Polygon: [
        {
            key: "WTST",
            value: {
                address: contractAddress?.[NetworkTypeDTO.polygon].otfProxyAddress?.toLowerCase(),
                symbol: "WTST",
                decimal: 18,
                icon: "tst",
                symbolPrice: "WTST",
                type: "evm",
                network: NetworkTypeDTO.polygon,
            },
            icon: "tst",
        },
    ],
    BNB: [
        {
            key: "WTST",
            value: {
                address: contractAddress?.[NetworkTypeDTO.binance].otfProxyAddress?.toLowerCase(),
                symbol: "WTST",
                decimal: 18,
                icon: "tst",
                symbolPrice: "WTST",
                type: "evm",
                network: NetworkTypeDTO.binance,
            },
            icon: "tst",
        },
    ],
    Ethereum: [],
    Arbitrum: [],
    Optimism: [],
    BSquared: [],
    BOB: [],
};
const networkStakingOptions = [{ key: NetworkTypeDTO.polygon, value: NetworkTypeDTO.polygon, icon: "polygon" }];
const networkFarmingOptions = [
    { key: NetworkTypeDTO.polygon, value: NetworkTypeDTO.polygon, icon: "polygon" },
    ...(!isTestnet ? [{ key: NetworkTypeDTO.bsquared, value: NetworkTypeDTO.bsquared, icon: "bsquared" }] : []),
    ...(!isTestnet ? [{ key: NetworkTypeDTO.bob, value: NetworkTypeDTO.bob, icon: "bob" }] : []),
    ...(!isTestnet ? [{ key: NetworkTypeDTO.binance, value: NetworkTypeDTO.binance, icon: "binance" }] : []),
];
const networkNoneFarmingOptions = [
    { key: NetworkTypeDTO.polygon, value: NetworkTypeDTO.polygon, icon: "polygon" },
    { key: NetworkTypeDTO.binance, value: NetworkTypeDTO.binance, icon: "binance" },
];
const networkOptions = [{ key: "Bitcoin", value: "Bitcoin", icon: "BTC" }, ...networkFarmingOptions];
const networkCCExchangeOptions = [
    { key: "Bitcoin", value: "Bitcoin", icon: "BTC" },
    { key: "All", value: "All", icon: null },
    { key: NetworkTypeDTO.ethereum, value: NetworkTypeDTO.ethereum, icon: "ethereum" },
    ...networkFarmingOptions,
    ...(!isTestnet ? [{ key: NetworkTypeDTO.arbitrum, value: NetworkTypeDTO.arbitrum, icon: "arbitrum" }] : []),
    ...(!isTestnet ? [{ key: NetworkTypeDTO.optimism, value: NetworkTypeDTO.optimism, icon: "optimism" }] : []),
];

const snapId = "npm:@gobob/bob-snap";
const snapWalletLink = "https://snaps.metamask.io/snap/npm/bob-snap/";

// const snapId = "npm:btcsnap";
// const snapWalletLink = "https://snaps.metamask.io/snap/npm/gobob/bob-snap/";

const nativePolygonTokenAddress = "0x0000000000000000000000000000000000000001";
const nativeBNBTokenAddress = "0x0000000000000000000000000000000000000002";
const nativeETHTokenAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

const firstNetwork = NetworkTypeDTO.polygon;
const firstNetworkFarming = NetworkTypeDTO.polygon;
const firstNetworkOTF = NetworkTypeDTO.binance;
const firstNetworkVesting = NetworkTypeDTO.ethereum;
const firstNetworkStaking = NetworkTypeDTO.ethereum;
const firstNetworkUnstaking = NetworkTypeDTO.polygon;

const oftLogicABI = [
    {
        inputs: [
            {
                internalType: "string",
                name: "_name",
                type: "string",
            },
            {
                internalType: "string",
                name: "_symbol",
                type: "string",
            },
            {
                internalType: "uint8",
                name: "_sharedDecimals",
                type: "uint8",
            },
            {
                internalType: "address",
                name: "_lzEndpoint",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "_hash",
                type: "bytes32",
            },
        ],
        name: "CallOFTReceivedSuccess",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
            },
        ],
        name: "Initialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_reason",
                type: "bytes",
            },
        ],
        name: "MessageFailed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "_address",
                type: "address",
            },
        ],
        name: "NonContractAddress",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "ReceiveFromChain",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "_payloadHash",
                type: "bytes32",
            },
        ],
        name: "RetryMessageSuccess",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_from",
                type: "address",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "_toAddress",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "SendToChain",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "feeBp",
                type: "uint16",
            },
        ],
        name: "SetDefaultFeeBp",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "dstchainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
            {
                indexed: false,
                internalType: "uint16",
                name: "feeBp",
                type: "uint16",
            },
        ],
        name: "SetFeeBp",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "feeOwner",
                type: "address",
            },
        ],
        name: "SetFeeOwner",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "uint16",
                name: "_type",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_minDstGas",
                type: "uint256",
            },
        ],
        name: "SetMinDstGas",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "precrime",
                type: "address",
            },
        ],
        name: "SetPrecrime",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "_remoteChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_path",
                type: "bytes",
            },
        ],
        name: "SetTrustedRemote",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint16",
                name: "_remoteChainId",
                type: "uint16",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_remoteAddress",
                type: "bytes",
            },
        ],
        name: "SetTrustedRemoteAddress",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        inputs: [],
        name: "BP_DENOMINATOR",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "DEFAULT_PAYLOAD_SIZE_LIMIT",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "NO_EXTRA_GAS",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "PT_SEND",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "PT_SEND_AND_CALL",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
        ],
        name: "allowance",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "approve",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "balanceOf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                internalType: "bytes32",
                name: "_from",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "_gasForCall",
                type: "uint256",
            },
        ],
        name: "callOnOFTReceived",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        name: "chainIdToFeeBps",
        outputs: [
            {
                internalType: "uint16",
                name: "feeBP",
                type: "uint16",
            },
            {
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "circulatingSupply",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "",
                type: "uint64",
            },
        ],
        name: "creditedPackets",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "decimals",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "subtractedValue",
                type: "uint256",
            },
        ],
        name: "decreaseAllowance",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "defaultFeeBp",
        outputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes32",
                name: "_toAddress",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "_dstGasForCall",
                type: "uint64",
            },
            {
                internalType: "bool",
                name: "_useZro",
                type: "bool",
            },
            {
                internalType: "bytes",
                name: "_adapterParams",
                type: "bytes",
            },
        ],
        name: "estimateSendAndCallFee",
        outputs: [
            {
                internalType: "uint256",
                name: "nativeFee",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "zroFee",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes32",
                name: "_toAddress",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_useZro",
                type: "bool",
            },
            {
                internalType: "bytes",
                name: "_adapterParams",
                type: "bytes",
            },
        ],
        name: "estimateSendFee",
        outputs: [
            {
                internalType: "uint256",
                name: "nativeFee",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "zroFee",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "",
                type: "uint64",
            },
        ],
        name: "failedMessages",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "feeOwner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
        ],
        name: "forceResumeReceive",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_version",
                type: "uint16",
            },
            {
                internalType: "uint16",
                name: "_chainId",
                type: "uint16",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_configType",
                type: "uint256",
            },
        ],
        name: "getConfig",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_remoteChainId",
                type: "uint16",
            },
        ],
        name: "getTrustedRemoteAddress",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "addedValue",
                type: "uint256",
            },
        ],
        name: "increaseAllowance",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_name",
                type: "string",
            },
            {
                internalType: "string",
                name: "_symbol",
                type: "string",
            },
            {
                internalType: "uint8",
                name: "_sharedDecimals",
                type: "uint8",
            },
            {
                internalType: "address",
                name: "_lzEndpoint",
                type: "address",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
        ],
        name: "isTrustedRemote",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "lzEndpoint",
        outputs: [
            {
                internalType: "contract ILayerZeroEndpoint",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
        ],
        name: "lzReceive",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        name: "minDstGasLookup",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
        ],
        name: "nonblockingLzReceive",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        name: "payloadSizeLimitLookup",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "precrime",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "quoteOFTFee",
        outputs: [
            {
                internalType: "uint256",
                name: "fee",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_srcChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_srcAddress",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "_nonce",
                type: "uint64",
            },
            {
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
        ],
        name: "retryMessage",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_from",
                type: "address",
            },
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes32",
                name: "_toAddress",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_minAmount",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "_payload",
                type: "bytes",
            },
            {
                internalType: "uint64",
                name: "_dstGasForCall",
                type: "uint64",
            },
            {
                components: [
                    {
                        internalType: "address payable",
                        name: "refundAddress",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "zroPaymentAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "adapterParams",
                        type: "bytes",
                    },
                ],
                internalType: "struct ICommonOFT.LzCallParams",
                name: "_callParams",
                type: "tuple",
            },
        ],
        name: "sendAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_from",
                type: "address",
            },
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bytes32",
                name: "_toAddress",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_minAmount",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "address payable",
                        name: "refundAddress",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "zroPaymentAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "adapterParams",
                        type: "bytes",
                    },
                ],
                internalType: "struct ICommonOFT.LzCallParams",
                name: "_callParams",
                type: "tuple",
            },
        ],
        name: "sendFrom",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_version",
                type: "uint16",
            },
            {
                internalType: "uint16",
                name: "_chainId",
                type: "uint16",
            },
            {
                internalType: "uint256",
                name: "_configType",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "_config",
                type: "bytes",
            },
        ],
        name: "setConfig",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_feeBp",
                type: "uint16",
            },
        ],
        name: "setDefaultFeeBp",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "bool",
                name: "_enabled",
                type: "bool",
            },
            {
                internalType: "uint16",
                name: "_feeBp",
                type: "uint16",
            },
        ],
        name: "setFeeBp",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_feeOwner",
                type: "address",
            },
        ],
        name: "setFeeOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "uint16",
                name: "_packetType",
                type: "uint16",
            },
            {
                internalType: "uint256",
                name: "_minGas",
                type: "uint256",
            },
        ],
        name: "setMinDstGas",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_dstChainId",
                type: "uint16",
            },
            {
                internalType: "uint256",
                name: "_size",
                type: "uint256",
            },
        ],
        name: "setPayloadSizeLimit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_precrime",
                type: "address",
            },
        ],
        name: "setPrecrime",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_version",
                type: "uint16",
            },
        ],
        name: "setReceiveVersion",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_version",
                type: "uint16",
            },
        ],
        name: "setSendVersion",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_remoteChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_path",
                type: "bytes",
            },
        ],
        name: "setTrustedRemote",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "_remoteChainId",
                type: "uint16",
            },
            {
                internalType: "bytes",
                name: "_remoteAddress",
                type: "bytes",
            },
        ],
        name: "setTrustedRemoteAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "sharedDecimals",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4",
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "token",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalSupply",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "transfer",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "transferFrom",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        name: "trustedRemoteLookup",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];

const environment = config.environment;

export {
    environment,
    oftLogicABI,
    firstNetwork,
    firstNetworkFarming,
    firstNetworkVesting,
    firstNetworkStaking,
    firstNetworkUnstaking,
    firstNetworkOTF,
    nativePolygonTokenAddress,
    nativeBNBTokenAddress,
    nativeETHTokenAddress,
    networkOptions,
    networkCCExchangeOptions,
    networkFarmingOptions,
    networkStakingOptions,
    networkNoneFarmingOptions,
    tokenOptionsTELEBTC,
    exchangeTokenOptions,
    tokenOptionsWTST,
    expectedWaitingTime,
    dexList,
    slippageList,
    speedTypeList,
    unisatWalletLink,
    xverseWalletLink,
    okxWalletLink,
    phantomWalletLink,
    leatherWalletLink,
    xdefiWalletLink,
    networkChainsId,
    convertNetworkType,
    networkBgColors,
    networkColors,
    chainsDetects,
    snapType,
    bip32NetworkConfig,
    dAppConfigMetaData,
    networks,
    subgraph,
    contractAddress,
    transactionBTCEndpoint,
    intervalDataUpdate,
    intervalAutoUpdateDataUpdate,
    normalConfirm,
    normalBurnConfirm,
    lowBurnConfirm,
    sourceNetwork,
    targetNetworkConnectionInfo,
    tokensList,
    tokensListFarming,
    chainId,
    appId,
    appIdBrc20Transfer,
    appIdBrc20Exchange,
    appIdRuneTransfer,
    appIdRuneExchange,
    dexItems,
    chains,
    isTestnet,
    JsonRpcProviderUrl,
    JsonRpcProviderOption,
    baseAPI,
    mempoolAPI,
    projectName,
    txEndpoint,
    lpTokens,
    snapId,
    snapWalletLink,
    quickSwapV2AppId,
    quickSwapV3AppId,
    iZiSwapAppId,
};
