import { useMemo } from "react";
import { Signer, ethers } from "ethers";
import { Provider } from "@ethersproject/providers";
import { useSignerState } from "states/signerContext";
import { useSetChain } from "@web3-onboard/react";
import { JsonRpcProviderUrl, networkChainsId } from "./configs";
import { NetworkTypeDTO } from "models/DTO";

const getOptionJsonRpcProvider = (value: any) => {
    return value;
};
export function useContract<C>(
    connector: (address: string, signerOrProvider: Signer | Provider) => C,
    contractAddress: string,
    _network: NetworkTypeDTO,
    isRead: boolean,
    useRealNetwork: boolean = false,
): C | undefined {
    const { provider: signerOrProvider, optionJsonRpcProvider } = useSignerState();

    const [{ connectedChain }, setChain] = useSetChain();
    // call the factory connector

    const network = useRealNetwork ? _network : getValidNetwork(_network);
    // connectedChain && connectedChain?.id !== networkChainsId[network] &&
    return useMemo(
        () =>
            contractAddress &&
            contractAddress !== "0x00" &&
            contractAddress !== "" &&
            signerOrProvider &&
            optionJsonRpcProvider
                ? isRead
                    ? connector(contractAddress, getOptionJsonRpcProvider(optionJsonRpcProvider?.[network]))
                    : connectedChain && connectedChain?.id === networkChainsId[_network]
                      ? connector(contractAddress, signerOrProvider)
                      : undefined
                : undefined,
        [connector, contractAddress, signerOrProvider, connectedChain, network, optionJsonRpcProvider],
    );
}
export function useContractABI(
    abi: Array<any>,
    contractAddress: string,
    _network: NetworkTypeDTO,
    isRead: boolean,
    useRealNetwork: boolean = false,
): any | undefined {
    const { provider: signerOrProvider, optionJsonRpcProvider } = useSignerState();
    const [{ connectedChain }, setChain] = useSetChain();
    // call the factory connector

    const network = useRealNetwork ? _network : getValidNetwork(_network);
    // connectedChain && connectedChain?.id !== networkChainsId[network] &&
    return useMemo(
        () =>
            contractAddress && contractAddress !== "0x00" && contractAddress !== "" && signerOrProvider
                ? isRead && optionJsonRpcProvider
                    ? new ethers.Contract(
                          contractAddress,
                          abi,
                          getOptionJsonRpcProvider(optionJsonRpcProvider?.[network]),
                      )
                    : connectedChain && connectedChain?.id === networkChainsId[_network]
                      ? new ethers.Contract(contractAddress, abi, signerOrProvider)
                      : undefined
                : undefined,
        [abi, contractAddress, signerOrProvider, connectedChain, network, optionJsonRpcProvider],
    );
}

export const getValidNetwork = (_network?: NetworkTypeDTO) => {
    const network =
        (_network && isCrossNetwork(_network) ? NetworkTypeDTO.polygon : _network) || NetworkTypeDTO.polygon;
    return network;
};

export const isCrossNetwork = (_network: NetworkTypeDTO) => {
    if ([NetworkTypeDTO.ethereum, NetworkTypeDTO.arbitrum, NetworkTypeDTO.optimism].includes(_network)) {
        return true;
    } else return false;
};
