import classNames from "classnames";

export default function Loading() {
    return (
        <span className={classNames("  flex items-center px-2 justify-center  text-core ")}>
            <svg
                className={classNames("animate-spin-slow", "text-primaryColor")}
                xmlns="http://www.w3.org/2000/svg"
                width="88"
                height="88"
                viewBox="0 0 44 44"
                fill="none"
            >
                <g opacity="0.3">
                    <path
                        d="M22 4C31.9342 4 40 12.0658 40 22C40 31.9342 31.9342 40 22 40C12.0658 40 4 31.9342 4 22C4 12.0658 12.0658 4 22 4Z"
                        stroke="#7E8698"
                        strokeWidth="6"
                    />
                </g>
                <path
                    d="M8.11135 10.5471C9.07981 9.3734 10.214 8.30446 11.508 7.37602C11.739 7.21029 11.9725 7.05084 12.2085 6.89763"
                    stroke="#35AC82"
                    strokeWidth="6"
                    strokeLinecap="round"
                />
            </svg>
        </span>
    );
}
