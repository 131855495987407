import { getValidNetwork, isCrossNetwork } from "utils/useContract";
import { toCustomTokenValue } from "utils/convert";
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    exchangeTokenOptions,
    baseAPI,
    chainsDetects,
    contractAddress,
    tokensList,
    tokensListFarming,
    transactionBTCEndpoint,
    txEndpoint,
    nativeETHTokenAddress,
    isTestnet,
} from "utils/configs";
import { parseCustomTokenValue, setDigitWithFractionMax } from "utils/convert";
import { formatNumber, numberToWord, sortBy } from "utils/functions";
import useRequest from "utils/useRequest";
import { BigNumber, ethers } from "ethers";
import { useConnectWallet } from "@web3-onboard/react";
import { NetworkTypeDTO } from "models/DTO";

export const useTeleSwap = () => {
    const [{ wallet }, connect, disconnect] = useConnectWallet();
    const account = wallet?.accounts[0]?.address;

    const request = useRequest();

    const getInfo = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/info/`);
            return res?.data;
        } catch (error) {
            return null;
        }
    };
    const getVolume = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/info/`);

            return formatNumber(res?.data?.allTimeVolume, 2);
        } catch (error) {
            return null;
        }
    };
    const getTVl = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/info/`);

            return numberToWord(res?.data?.tvl);
        } catch (error) {
            return null;
        }
    };

    const getStatus = (requestType: string, status: string) => {
        const wrapPendingStatus = ["Pending", "Confirmed"];
        const unwrapPendingStatus = ["NotProcessed"];

        if (status === "Failed") return "Failed";
        // done failed
        if (["transfer", "exchange"].includes(requestType)) {
            if (wrapPendingStatus.includes(status)) return "Pending";
        }
        if (["burn"].includes(requestType)) {
            if (unwrapPendingStatus.includes(status)) return "Pending";
        }

        return "Completed";
    };

    const _mapperLockerDetails = (data: any, dataInfo: any) => {
        const item = {
            totalLockerItem: +data?.numberOfLocker,
            totalBurnItem: 0,
            totalMintItem: 0,
            submittedRequests: +data?.numberOfSubmittedRequests,
            lockerPercentageFee: "0.15",
            liquidationRatio: 0,
            collateralRatio: 0,
            totalMint: dataInfo?.mintedTeleBTC,
            totalBurn: dataInfo?.burnTeleBTC,
            allTimeVolume: dataInfo?.allTimeVolume,
            tvl: dataInfo?.tvl,
        };
        return item;
    };
    const getLockerDetails = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/lockers/info/`);
            const resInfo: any = await request.get(`${baseAPI}/teleswap/info/`);
            const lockersInfo = _mapperLockerDetails(res?.data, resInfo?.data);
            return lockersInfo;
        } catch (error) {
            return null;
        }
    };

    const _mapperLocker = (data: any) => {
        const findChain = chainsDetects.find((chain: any) => chain.chainId === +data?.network?.chainId);
        const findChainNetwork = findChain?.chainNetwork || NetworkTypeDTO.polygon;
        const findNativeToken = findChainNetwork === NetworkTypeDTO.bob ? "WBTC" : findChain?.token; // TODO if for bob

        const item = {
            evmAddress: data?.targetAddress,
            bitcoinAddress: data?.sourceAddress,
            depositedCollateral: data?.nativeTokenLockedAmount,
            nativeTokenLockedAmount: data?.nativeTokenLockedAmount,
            availableTotalCapacity: data?.capacity,
            netMinted: data?.netMinted,
            usedCapacity: data?.netMinted,
            healthFactor: data?.healthFactor || Infinity,
            joinDate: new Date(data?.createdAt).getTime(),
            network: findChainNetwork,
            txNetworkEndpoint: txEndpoint?.[findChainNetwork],
            nativeToken: findNativeToken,
        };
        return item;
    };
    const getLockers = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/lockers/`);
            const lockers = (res?.data?.lockers || []).map((i: any) => {
                const _i = _mapperLocker(i);
                return _i;
            });

            return sortBy(lockers, "healthFactor", "decs");
        } catch (error) {
            return null;
        }
    };

    const _mapperTeleswapDetails = (data: any, dataInfo: any) => {
        const item = {
            totalTeleporterItem: +data?.numberOfTeleporters,
            totalBurnItem: 0,
            totalTransferItem: 0,
            totalExchangeItem: 0,
            submittedRequests: +data?.submittedRequests,
            totalRequests: +dataInfo?.totalRequests,
            totalUserItem: 0,
            totalBTCTransferred: data?.totalTransferredBTC,
            totalFee: data?.collectedRewards,
        };
        return item;
    };
    const getTeleswapDetails = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/teleporters/info/`);
            const resInfo: any = await request.get(`${baseAPI}/teleswap/info/`);
            const lockersInfo = _mapperTeleswapDetails(res?.data, resInfo?.data);
            return lockersInfo;
        } catch (error) {
            return null;
        }
    };

    const _mapperTeleporter = (data: any) => {
        const findChain = chainsDetects.find((chain: any) => chain.chainId === +data?.network?.chainId);
        const findChainNetwork = findChain?.chainNetwork || NetworkTypeDTO.polygon;
        const item = {
            address: data?.address,
            id: data?.address,
            totalFee: data?.totalTeleporterFee,
            totalBTCTransferred: data?.totalTransferredBTC,
            lastActivityBlockNumber: data?.lastActivity,
            totalTransferItem: +data?.totalTransfer,
            totalExchangeItem: +data?.totalExchange,
            submittedRequests: +data?.totalExchange + +data?.totalTransfer,
            network: findChainNetwork,
            txNetworkEndpoint: txEndpoint?.[findChainNetwork],
        };

        return item;
    };
    const getTeleporters = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/teleporters/`);
            const lockers = (res?.data?.teleporters || []).map((i: any) => {
                const _i = _mapperTeleporter(i);
                return _i;
            });

            return lockers;
        } catch (error) {
            return null;
        }
    };

    const getRelayersInfo = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/relayers/info/`);
            return res?.data;
        } catch (error) {
            return null;
        }
    };
    const getRelayers = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/relayers/`);
            return res?.data;
        } catch (error) {
            return null;
        }
    };

    const getBlocks = async () => {
        try {
            const res: any = await request.get(`${baseAPI}/teleswap/blocks/`);
            return res?.data;
        } catch (error) {
            return null;
        }
    };

    const getSupportedBrc20TokensExchange = async () => {
        try {
            let runeRes: any = [];
            try {
                runeRes = await request.get(`${baseAPI}/rune/supported-rune-tokens/`);
            } catch (e) {
                console.log(e);
            }
            const brc20Res: any = await request.get(`${baseAPI}/brc20/supported-brc20-tokens/`);

            const runeTokenOptionsPolygon = (runeRes?.data?.polygon || []).map((i: any) => {
                const _i = {
                    key: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                    value: {
                        address: i.address?.trim()?.toLowerCase(),
                        symbol: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                        decimal: i.decimal,
                        icon: i.symbol?.toLowerCase(),
                        symbolPrice: i.symbol?.toUpperCase(),
                        name: i.name,
                        type: "rune",
                        network: "Bitcoin",
                    },
                    icon: i.symbol?.toLowerCase(),
                };
                return _i;
            });
            const runeEVMTokenOptionsPolygon = (runeRes?.data?.polygon || []).map((i: any) => {
                const _i = {
                    key: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                    value: {
                        address: i.address?.trim()?.toLowerCase(),
                        symbol: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                        decimal: i.decimal,
                        icon: i.symbol?.toLowerCase(),
                        symbolPrice: i.symbol?.toUpperCase(),
                        name: i.name,
                        type: "evm-rune",
                        network: NetworkTypeDTO.polygon,
                    },
                    icon: i.symbol?.toLowerCase(),
                };
                return _i;
            });

            const brc20TokenOptionsBSC = (brc20Res?.data?.bsc || [])
                .filter((i: any) => i.tick !== "sats")
                .map((i: any) => {
                    const _i = {
                        key: i.tick?.toUpperCase(),
                        value: {
                            address: i.address?.toLowerCase(),
                            symbol: i.tick?.toUpperCase(),
                            decimal: i.decimal,
                            icon: i.tick?.toLowerCase(),
                            symbolPrice: i.tick?.toUpperCase(),
                            type: "brc20",
                            network: "Bitcoin",
                        },
                        icon: i.tick?.toLowerCase(),
                    };
                    return _i;
                });
            const brc20EVMWrapTokenOptionsBSC = (brc20Res?.data?.bsc || [])
                .filter((i: any) => i.tick !== "sats")
                .map((i: any) => {
                    const _i = {
                        key: i.tick?.toUpperCase(),
                        value: {
                            address: i.address?.toLowerCase(),
                            symbol: i.tick?.toUpperCase(),
                            decimal: i.decimal,
                            icon: i.tick?.toLowerCase(),
                            symbolPrice: i.tick?.toUpperCase(),
                            type: "evm-brc20",
                            network: NetworkTypeDTO.binance,
                        },
                        icon: i.tick?.toLowerCase(),
                    };
                    return _i;
                });

            const runeTokenOptionsOther = (runeRes?.data?.polygon || []).map((i: any) => {
                const _i = {
                    key: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                    value: {
                        address: i.address?.trim()?.toLowerCase(),
                        symbol: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                        decimal: i.decimal,
                        icon: i.symbol?.toLowerCase(),
                        symbolPrice: i.symbol?.toUpperCase(),
                        name: i.name,
                        type: "rune",
                        network: "Bitcoin",
                        disable: true,
                    },
                    icon: i.symbol?.toLowerCase(),
                };
                return _i;
            });

            const getRuneEVMTokenOptionsOther = (network: string) => {
                return (runeRes?.data?.polygon || []).map((i: any) => {
                    const _i = {
                        key: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                        value: {
                            address: i.address?.trim()?.toLowerCase(),
                            symbol: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                            decimal: i.decimal,
                            icon: i.symbol?.toLowerCase(),
                            symbolPrice: i.symbol?.toUpperCase(),
                            name: i.name,
                            type: "evm-rune",
                            network: network,
                            disable: true,
                        },
                        icon: i.symbol?.toLowerCase(),
                    };
                    return _i;
                });
            };

            const brc20TokenOptionsOther = (brc20Res?.data?.bsc || [])
                .filter((i: any) => i.tick !== "sats")
                .map((i: any) => {
                    const _i = {
                        key: i.tick?.toUpperCase(),
                        value: {
                            address: i.address?.toLowerCase(),
                            symbol: i.tick?.toUpperCase(),
                            decimal: i.decimal,
                            icon: i.tick?.toLowerCase(),
                            symbolPrice: i.tick?.toUpperCase(),
                            type: "brc20",
                            network: "Bitcoin",
                            disable: true,
                        },
                        icon: i.tick?.toLowerCase(),
                    };
                    return _i;
                });

            const getBrc20EVMWrapTokenOptionsOther = (network: string) => {
                return (brc20Res?.data?.bsc || [])
                    .filter((i: any) => i.tick !== "sats")
                    .map((i: any) => {
                        const _i = {
                            key: i.tick?.toUpperCase(),
                            value: {
                                address: i.address?.toLowerCase(),
                                symbol: i.tick?.toUpperCase(),
                                decimal: i.decimal,
                                icon: i.tick?.toLowerCase(),
                                symbolPrice: i.tick?.toUpperCase(),
                                type: "evm-brc20",
                                network: network,
                                disable: true,
                            },
                            icon: i.tick?.toLowerCase(),
                        };
                        return _i;
                    });
            };

            const tokenOptions: any = {
                All: [
                    ...runeEVMTokenOptionsPolygon,
                    ...exchangeTokenOptions?.[NetworkTypeDTO.polygon],
                    ...runeTokenOptionsPolygon,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.polygon),

                    ...brc20EVMWrapTokenOptionsBSC,
                    ...exchangeTokenOptions?.[NetworkTypeDTO.binance],
                    ...brc20TokenOptionsBSC,
                    ...runeTokenOptionsOther,
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.binance),

                    ...exchangeTokenOptions?.[NetworkTypeDTO.bsquared],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.bsquared),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.bsquared),

                    ...exchangeTokenOptions?.[NetworkTypeDTO.bob],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.bob),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.bob),

                    ...exchangeTokenOptions?.[NetworkTypeDTO.ethereum],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.ethereum),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.ethereum),

                    ...exchangeTokenOptions?.[NetworkTypeDTO.arbitrum],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.arbitrum),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.arbitrum),

                    ...exchangeTokenOptions?.[NetworkTypeDTO.optimism],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.optimism),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.optimism),
                ],
                Polygon: [
                    ...runeEVMTokenOptionsPolygon,
                    ...exchangeTokenOptions?.[NetworkTypeDTO.polygon],
                    ...runeTokenOptionsPolygon,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.polygon),
                ],
                BNB: [
                    ...brc20EVMWrapTokenOptionsBSC,
                    ...exchangeTokenOptions?.[NetworkTypeDTO.binance],
                    ...brc20TokenOptionsBSC,
                    ...runeTokenOptionsOther,
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.binance),
                ],
                BSquared: [
                    ...exchangeTokenOptions?.[NetworkTypeDTO.bsquared],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.bsquared),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.bsquared),
                ],
                BOB: [
                    ...exchangeTokenOptions?.[NetworkTypeDTO.bob],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.bob),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.bob),
                ],
                Ethereum: [
                    ...exchangeTokenOptions?.[NetworkTypeDTO.ethereum],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.ethereum),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.ethereum),
                ],
                Arbitrum: [
                    ...exchangeTokenOptions?.[NetworkTypeDTO.arbitrum],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.arbitrum),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.arbitrum),
                ],
                Optimism: [
                    ...exchangeTokenOptions?.[NetworkTypeDTO.optimism],
                    ...runeTokenOptionsOther,
                    ...brc20TokenOptionsOther,
                    ...getBrc20EVMWrapTokenOptionsOther(NetworkTypeDTO.optimism),
                    ...getRuneEVMTokenOptionsOther(NetworkTypeDTO.optimism),
                ],
            };
            return tokenOptions;
        } catch (error) {
            const tokenOptions: any = exchangeTokenOptions;
            return tokenOptions;
        }
    };
    const getSupportedBrc20TokensFarming = async () => {
        try {
            let runeRes: any = [];
            try {
                runeRes = await request.get(`${baseAPI}/rune/supported-rune-tokens/`);
            } catch (e) {
                console.log(e);
            }
            const brc20Res: any = await request.get(`${baseAPI}/brc20/supported-brc20-tokens/`);
            const runeTokenOptionsPolygon = (runeRes?.data?.polygon || []).map((i: any) => {
                const _i = {
                    key: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                    value: {
                        address: i.address?.trim()?.toLowerCase(),
                        symbol: isTestnet ? "DOG" : i.symbol?.toUpperCase(),
                        decimal: i.decimal,
                        icon: i.symbol?.toLowerCase(),
                        symbolPrice: i.symbol?.toUpperCase(),
                        name: i.name,
                    },
                    icon: i.symbol?.toLowerCase(),
                };
                return _i;
            });
            const brc20TokenOptionsBSC = (brc20Res?.data?.bsc || [])
                .filter((i: any) => i.tick !== "sats")
                .map((i: any) => {
                    const _i = {
                        key: i.tick?.toUpperCase(),
                        value: {
                            address: i.address?.toLowerCase(),
                            symbol: i.tick?.toUpperCase(),
                            decimal: i.decimal,
                            icon: i.tick?.toLowerCase(),
                            symbolPrice: i.tick?.toUpperCase(),
                        },
                        icon: i.tick?.toLowerCase(),
                    };
                    return _i;
                });

            const tokenOptions: any = {
                Polygon: [...runeTokenOptionsPolygon, ...tokensListFarming?.[NetworkTypeDTO.polygon]],
                BNB: [...tokensListFarming?.[NetworkTypeDTO.binance], ...brc20TokenOptionsBSC],
                BSquared: [...tokensListFarming?.[NetworkTypeDTO.bsquared]],
                BOB: [...tokensListFarming?.[NetworkTypeDTO.bob]],
                Ethereum: [...tokensListFarming?.[NetworkTypeDTO.ethereum]],
                Arbitrum: [...tokensListFarming?.[NetworkTypeDTO.arbitrum]],
                Optimism: [...tokensListFarming?.[NetworkTypeDTO.optimism]],
            };

            const liquidityPools: any = {
                Polygon: tokenOptions.Polygon.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                BNB: tokenOptions.BNB.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                Ethereum: tokenOptions.Ethereum.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                Arbitrum: tokenOptions.Arbitrum.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                Optimism: tokenOptions.Optimism.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                BSquared: tokenOptions.BSquared.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                BOB: tokenOptions.BOB.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
            };

            return { tokenOptions, liquidityPools };
        } catch (error) {
            const tokenOptions: any = tokensListFarming;
            const liquidityPools: any = {
                Polygon: tokenOptions.Polygon.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                BNB: tokenOptions.Polygon.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                Ethereum: tokenOptions.Ethereum.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                Arbitrum: tokenOptions.Arbitrum.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                Optimism: tokenOptions.Optimism.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                BSquared: tokenOptions.BSquared.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
                BOB: tokenOptions.BOB.map((i: any) => {
                    const _i = {
                        key: `TELEBTC-${i?.value?.symbol}`,
                        value: `TELEBTC-${i?.value?.symbol}`,
                        icon: "TELEBTC",
                        iconPlus: i?.value?.icon,
                    };
                    return _i;
                }),
            };
            return { tokenOptions, liquidityPools };
        }
    };

    const _mapperCCExchange = (data: any) => {
        const findChain = chainsDetects.find(
            (chain: any) =>
                +chain.chainId ===
                (+data?.crossChain?.targetNetwork?.chainId || +data?.event?.targetTransaction?.network?.chainId),
        );

        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const outputToken: any = {
            address: data?.exchangeToken?.contractAddress?.toLowerCase(),
            symbol: data?.exchangeToken?.symbol,
            decimal: data?.exchangeToken?.decimal,
            icon: data?.exchangeToken?.symbol?.toLowerCase(),
            symbolPrice: data?.exchangeToken?.symbol,
            type: "evm",
        };

        const inputAmount = data?.amount; //BTC
        const teleBTCAmount = data?.receivedAmount; //teleBTC // or  parseCustomTokenValue(data?.details?.inputAndOutputAmount?.[0], 8)
        const outputAmount = parseCustomTokenValue(data?.details?.inputAndOutputAmount?.[1], +outputToken?.["decimal"]); //Coin

        const fee = setDigitWithFractionMax(+inputAmount - +teleBTCAmount);
        const myLastEvent = isCrossNetwork(evmNetwork) ? data?.crossChain?.targetEvent : data?.event; // TODO targetEvent

        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Exchange ${data?.isInstant ? "(instant)" : ""} ${
                data?.status?.includes("Failed") ? "(Failed!)" : ""
            }`,
            creationTime: new Date(data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: "BTC",
            inputTokenMain: {
                address: contractAddress?.[getValidNetwork(evmNetwork)].teleBTCAddress?.toLowerCase(),
                symbol: "TELEBTC",
                decimal: 8,
                icon: "TELEBTC",
                symbolPrice: "BTC",
                type: "btc",
            },
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} BTC on Bitcoin`,
            outputAmount: data?.status?.includes("Failed") ? teleBTCAmount : outputAmount,
            outputToken: data?.status?.includes("Failed") ? "TELEBTC" : outputToken?.["symbol"],
            outputAmountMain: outputAmount,
            outputTokenMain: outputToken,
            bitcoinAddress: data?.sourceTransaction?.inputs?.[0]?.address,
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: data?.status?.includes("Failed")
                ? `${teleBTCAmount}  ${`TELEBTC`} on ${evmNetwork}`
                : `${outputAmount}  ${outputToken?.["symbol"]} on ${evmNetwork}`,
            fee: `${fee} BTC`,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${myLastEvent?.targetTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Completed"
                  : "Completed",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };

        return tx;
    };
    const _mapperCCExchangePending = (data: any) => {
        const findChain = chainsDetects.find(
            (chain: any) => +chain.chainId === (+data?.crossChain?.targetNetwork?.chainId || +data?.chainId),
        );

        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const outputToken: any = {
            address: data?.exchangeToken?.contractAddress?.toLowerCase(),
            symbol: data?.exchangeToken?.symbol,
            decimal: data?.exchangeToken?.decimal,
            icon: data?.exchangeToken?.symbol?.toLowerCase(),
            symbolPrice: data?.exchangeToken?.symbol,
            type: "evm",
        };

        const inputAmount = data?.amount; //BTC
        const teleBTCAmount = data?.receivedAmount || 0; //teleBTC
        const outputAmount = parseCustomTokenValue(
            data?.exchangeOutputAmount || data?.exchangeOutputReceivedAmount,
            +outputToken?.["decimal"],
        ); //Coin

        const fee = setDigitWithFractionMax(+inputAmount - +teleBTCAmount);

        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Exchange ${data?.isInstant ? "(instant)" : ""} ${
                data?.status?.includes("Failed") ? "(Failed!)" : ""
            }`,
            creationTime: undefined,
            inputAmount,
            inputToken: "BTC",
            inputTokenMain: {
                address: contractAddress?.[getValidNetwork(evmNetwork)].teleBTCAddress?.toLowerCase(),
                symbol: "TELEBTC",
                decimal: 8,
                icon: "TELEBTC",
                symbolPrice: "BTC",
                type: "btc",
            },
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} BTC on Bitcoin`,
            outputAmount: data?.status?.includes("Failed") ? teleBTCAmount : outputAmount,
            outputToken: data?.status?.includes("Failed") ? "TELEBTC" : outputToken?.["symbol"],
            outputAmountMain: outputAmount,
            outputTokenMain: outputToken,
            bitcoinAddress: data?.sourceTransaction?.inputs?.[0]?.address,
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: data?.status?.includes("Failed")
                ? `${teleBTCAmount}  ${`TELEBTC`} on ${evmNetwork}`
                : `${outputAmount}  ${outputToken?.["symbol"]} on ${evmNetwork}`,
            fee: `${fee} BTC`,
            blockNumber: data?.sourceTransaction?.blockNumber,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${undefined}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            nowDate: new Date().getTime(),
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Pending"
                  : "Pending",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };
    const _mapperCCExchangeBrc20 = (data: any) => {
        const findChain = chainsDetects.find(
            (chain: any) =>
                +chain.chainId ===
                (+data?.event?.targetTransaction?.network?.chainId === 8002
                    ? 80002
                    : +data?.event?.targetTransaction?.network?.chainId),
        );
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const outputToken: any =
            tokensList?.[evmNetwork].find(
                (i: any) => i.address?.toLowerCase() === data?.outputToken?.contractAddress?.toLowerCase(),
            ) || data?.outputToken;

        const inputAmount = parseCustomTokenValue(data?.inputTokenAmount, data?.inputToken?.decimal); //brc20
        const brc20Amount = data?.amount; //brc20 Wrap
        const outputAmount = parseCustomTokenValue(
            data?.outputTokenReceivedAmount && data?.outputTokenReceivedAmount !== "0"
                ? data?.outputTokenReceivedAmount
                : data?.outputTokenAmount,
            data?.outputToken?.decimal,
        ); //coin

        const fee = setDigitWithFractionMax(+inputAmount - +brc20Amount);

        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Exchange ${data?.isInstant ? "(instant)" : ""} ${
                data?.status?.includes("Failed") ? "(Failed!)" : ""
            }`,
            creationTime: new Date(data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: data?.inputToken?.symbol,
            inputTokenMain: {
                address: data?.inputToken?.contractAddress?.toLowerCase(),
                symbol: data?.inputToken?.symbol,
                decimal: data?.inputToken?.decimal,
                icon: data?.inputToken?.symbol?.toLowerCase(),
                symbolPrice: data?.inputToken?.symbol,
                type: data.tokenType?.toLowerCase(),
            },
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} ${data?.inputToken?.symbol} on Bitcoin`,
            outputAmount: data?.status?.includes("Failed") ? brc20Amount : outputAmount,
            outputToken: data?.status?.includes("Failed") ? data?.inputToken?.symbol : outputToken?.["symbol"],
            outputAmountMain: outputAmount,
            outputTokenMain: outputToken,
            bitcoinAddress: data?.sourceTransaction?.inputs?.[0]?.address,
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: data?.status?.includes("Failed")
                ? `${brc20Amount}  ${data?.inputToken?.symbol} on ${evmNetwork}`
                : `${outputAmount}  ${outputToken?.["symbol"]} on ${evmNetwork}`,
            fee: `${fee} ${data?.inputToken?.symbol}`,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${data?.event?.targetTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Completed"
                  : "Completed",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };

        return tx;
    };
    const _mapperCCExchangeBrc20Pending = (data: any) => {
        const findChain = chainsDetects.find(
            (chain: any) =>
                +chain.chainId ===
                (+(data?.event?.targetTransaction?.network?.chainId || data?.chainId) === 8002
                    ? 80002
                    : +(data?.event?.targetTransaction?.network?.chainId || data?.chainId)),
        );
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const outputToken: any =
            tokensList?.[evmNetwork].find(
                (i: any) => i.address?.toLowerCase() === data?.outputToken?.contractAddress?.toLowerCase(),
            ) || data?.outputToken;

        const inputAmount = parseCustomTokenValue(data?.inputTokenAmount, data?.inputToken?.decimal); //brc20
        const brc20Amount = data?.amount; //brc20 Wrap
        const outputAmount = parseCustomTokenValue(
            data?.outputTokenReceivedAmount && data?.outputTokenReceivedAmount !== "0"
                ? data?.outputTokenReceivedAmount
                : data?.outputTokenAmount,
            data?.outputToken?.decimal,
        ); //coin

        const fee = setDigitWithFractionMax(+inputAmount - +brc20Amount);

        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Exchange ${data?.isInstant ? "(instant)" : ""} ${
                data?.status?.includes("Failed") ? "(Failed!)" : ""
            }`,
            creationTime: undefined,
            inputAmount,
            inputToken: data?.inputToken?.symbol,
            inputTokenMain: {
                address: data?.inputToken?.contractAddress?.toLowerCase(),
                symbol: data?.inputToken?.symbol,
                decimal: data?.inputToken?.decimal,
                icon: data?.inputToken?.symbol?.toLowerCase(),
                symbolPrice: data?.inputToken?.symbol,
                type: data.tokenType?.toLowerCase(),
            },
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} ${data?.inputToken?.symbol} on Bitcoin`,
            outputAmount: data?.status?.includes("Failed") ? brc20Amount : outputAmount,
            outputToken: data?.status?.includes("Failed") ? data?.inputToken?.symbol : outputToken?.["symbol"],
            outputAmountMain: outputAmount,
            outputTokenMain: outputToken,
            bitcoinAddress: data?.sourceTransaction?.inputs?.[0]?.address,
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: data?.status?.includes("Failed")
                ? `${brc20Amount}  ${data?.inputToken?.symbol} on ${evmNetwork}`
                : `${outputAmount}  ${outputToken?.["symbol"]} on ${evmNetwork}`,
            fee: `${fee} ${data?.inputToken?.symbol}`,
            blockNumber: data?.sourceTransaction?.blockNumber,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${undefined}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Pending"
                  : "Pending",
            nowDate: new Date().getTime(),
            evmNetwork,
            crosschainId: data?.crosschainId,
        };

        return tx;
    };

    const _mapperCCTransfer = (data: any) => {
        const inputAmount = data?.amount;
        const outputAmount = data?.receivedAmount || 0;
        const findChain = chainsDetects.find(
            (chain: any) =>
                +chain.chainId ===
                (+data?.crossChain?.targetNetwork?.chainId || +data?.event?.targetTransaction?.network?.chainId),
        );
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;
        const fee = setDigitWithFractionMax(+inputAmount - +outputAmount);
        const myLastEvent = isCrossNetwork(evmNetwork) ? data?.crossChain?.targetEvent : data?.event; // TODO targetEvent
        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Wrap ${data?.isInstant ? "(instant)" : ""} ${data?.status?.includes("Failed") ? "(Failed!)" : ""}`,
            creationTime: new Date(data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: "BTC",
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} BTC on Bitcoin`,
            outputAmount,
            outputToken: "TELEBTC",
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: `${outputAmount}  TELEBTC on ${evmNetwork}`,
            fee: `${fee} BTC`,
            blockNumber: 0,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${myLastEvent?.targetTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            nowDate: new Date().getTime(),
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Completed"
                  : "Completed",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };
    const _mapperCCTransferPending = (data: any) => {
        const inputAmount = data?.amount;
        const outputAmount = data?.receivedAmount || 0;
        const findChain = chainsDetects.find(
            (chain: any) => +chain.chainId === (+data?.crossChain?.targetNetwork?.chainId || +data?.chainId),
        );
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;
        const fee = setDigitWithFractionMax(+inputAmount - +outputAmount);
        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Wrap ${data?.isInstant ? "(instant)" : ""} ${data?.status?.includes("Failed") ? "(Failed!)" : ""}`,
            creationTime: undefined,
            inputAmount,
            inputToken: "BTC",
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} BTC on Bitcoin`,
            outputAmount,
            outputToken: "TELEBTC",
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: `${outputAmount}  TELEBTC on ${evmNetwork}`,
            fee: `${fee} BTC`,
            blockNumber: data?.sourceTransaction?.blockNumber,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${undefined}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            nowDate: new Date().getTime(),
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Pending"
                  : "Pending",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };
    const _mapperCCTransferBrc20 = (data: any) => {
        const inputAmount = parseCustomTokenValue(data?.inputTokenAmount, data?.inputToken?.decimal);
        const outputAmount = data?.amount;
        const findChain = chainsDetects.find(
            (chain: any) => +chain.chainId === +data?.event?.targetTransaction?.network?.chainId,
        );
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const fee = setDigitWithFractionMax(+inputAmount - +outputAmount);
        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Wrap ${data?.isInstant ? "(instant)" : ""} ${data?.status?.includes("Failed") ? "(Failed!)" : ""}`,
            creationTime: new Date(data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: data?.inputToken?.symbol,
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} ${data?.inputToken?.symbol} on Bitcoin`,
            outputAmount,
            outputToken: `${data?.inputToken?.symbol}`,
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: `${outputAmount}  ${data?.inputToken?.symbol} on ${evmNetwork}`,
            fee: `${fee} ${data?.inputToken?.symbol}`,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${data?.event?.targetTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Completed"
                  : "Completed",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };
    const _mapperCCTransferBrc20Pending = (data: any) => {
        const inputAmount = parseCustomTokenValue(data?.inputTokenAmount, data?.inputToken?.decimal);
        const outputAmount = data?.amount;
        const findChain = chainsDetects.find((chain: any) => +chain.chainId === +data?.chainId);
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const fee = setDigitWithFractionMax(+inputAmount - +outputAmount);
        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `Wrap ${data?.isInstant ? "(instant)" : ""} ${data?.status?.includes("Failed") ? "(Failed!)" : ""}`,
            creationTime: undefined,
            inputAmount,
            inputToken: data?.inputToken?.symbol,
            inputNetwork: "Bitcoin",
            sent: `${inputAmount} ${data?.inputToken?.symbol} on Bitcoin`,
            outputAmount,
            outputToken: `${data?.inputToken?.symbol}`,
            outputNetwork: evmNetwork,
            outputAddress: data?.recipientAddress,
            received: `${outputAmount}  ${data?.inputToken?.symbol} on ${evmNetwork}`,
            fee: `${fee} ${data?.inputToken?.symbol}`,
            blockNumber: data?.sourceTransaction?.blockNumber,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${undefined}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Pending"
                  : "Pending",
            nowDate: new Date().getTime(),
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };

    const _mapperCCBurn = (data: any, status: "Completed" | "Pending") => {
        const findChain = chainsDetects.find(
            (chain: any) =>
                chain.chainId ===
                (+data?.crossChain?.originNetwork?.chainId || +data?.burnEvent?.targetTransaction?.network?.chainId),
        );

        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const isTeleBTCToken =
            data?.burnEvent?.data?.inputToken?.toLowerCase() === "0x0000000000000000000000000000000000000000" ||
            data?.burnEvent?.data?.inputToken?.toLowerCase() ===
                contractAddress?.[getValidNetwork(evmNetwork)].teleBTCAddress?.toLowerCase();
        const inputToken = isTeleBTCToken
            ? { symbol: "TELEBTC", decimal: 8 }
            : tokensList?.[getValidNetwork(evmNetwork)].find(
                  (i: any) => i.address?.toLowerCase() === data?.burnEvent?.data?.inputToken?.toLowerCase(),
              ) || data?.inputToken;

        const teleBTCAmount = data?.amount;

        const inputAmount = isTeleBTCToken
            ? data?.amount
            : parseCustomTokenValue(
                  isCrossNetwork(evmNetwork) ? data?.crossChain?.paymentTokenAmount : data?.inputTokenAmount,
                  inputToken?.decimal || 8,
              );

        const outputAmount =
            (isCrossNetwork(evmNetwork) && status === "Pending"
                ? data?.crossChain?.receivedAmount
                : data?.burntAmount) || 0;
        let fee = 0;
        try {
            fee = parseCustomTokenValue(
                BigNumber.from(toCustomTokenValue(teleBTCAmount, 8)).mod(toCustomTokenValue(outputAmount, 8)),
                8,
            );
        } catch (error) {}

        const myLastEvent = isCrossNetwork(evmNetwork) ? data?.crossChain?.originEvent : data?.burnEvent;
        const tx = {
            id: data?.sourceTransaction?.txId,
            type: isTeleBTCToken
                ? `Unwrap ${data?.status === "Slashed" ? "(Slashed!)" : ""}`
                : `Exchange ${data?.status === "Slashed" ? "(Slashed!)" : ""}`,
            creationTime:
                new Date(isCrossNetwork(evmNetwork) ? data?.crossChain?.createdAt : data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: inputToken?.symbol,
            inputNetwork: evmNetwork,
            sent: `${inputAmount} ${inputToken?.symbol} on ${evmNetwork} `,
            outputAmount,
            outputToken: "BTC",
            outputNetwork: "Bitcoin",
            outputAddress: data?.userSourceAddress,
            received: outputAmount ? `${outputAmount}  BTC on Bitcoin ` : "",
            fee: `${fee} BTC`,
            blockNumber: data?.sourceTransaction?.blockNumber,

            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${myLastEvent?.targetTransaction?.txId}`,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: getStatus(data?.requestType, data?.status)?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? getStatus(data?.requestType, data?.status)
                  : getStatus(data?.requestType, data?.status),
            evmNetwork,
            crosschainId: data?.crosschainId,
        };

        return tx;
    };
    const _mapperCCBurnBrc20 = (data: any, status: string) => {
        const findChain = chainsDetects.find(
            (chain: any) => chain.chainId === +data?.unwrapEvent?.targetTransaction?.network?.chainId,
        );

        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;
        const inputToken =
            data?.type === "Unwrap"
                ? data?.inputToken
                : tokensList?.[evmNetwork].find(
                      (i: any) => i.address?.toLowerCase() === data?.unwrapEvent?.data?.inputToken?.toLowerCase(),
                  ) || data?.inputToken;

        const outputAmount = parseCustomTokenValue(data?.outputTokenAmount, data?.outputToken.decimal);

        const inputAmount = parseCustomTokenValue(data?.inputTokenAmount, inputToken?.decimal || 18);
        const brcAmountAsOutputAmount = setDigitWithFractionMax(data?.amount);
        const fee = setDigitWithFractionMax(+outputAmount - +brcAmountAsOutputAmount);
        const tx = {
            id: data?.sourceTransaction?.txId,
            type: `${data?.type === "Unwrap" ? "Unwrap" : "Exchange"} ${
                data?.status?.includes("Slashed") ? "(Slashed!)" : ""
            }`,
            creationTime: new Date(data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: inputToken?.symbol,
            inputTokenMain: {
                ...inputToken,
                type: data.tokenType?.toLowerCase(),
            },
            inputNetwork: evmNetwork,
            sent: `${inputAmount} ${inputToken?.symbol} on ${evmNetwork} `,
            outputAmount: brcAmountAsOutputAmount,
            outputToken: `${data?.outputToken?.symbol}`,
            outputNetwork: "Bitcoin",
            outputAddress: data?.userSourceAddress,
            received: brcAmountAsOutputAmount
                ? `${brcAmountAsOutputAmount}  ${data?.outputToken?.symbol} on Bitcoin `
                : "",
            fee: `${fee} ${data?.outputToken?.symbol}`,
            blockNumber: data?.sourceTransaction?.blockNumber,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${data?.unwrapEvent?.targetTransaction?.txId}`,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? getStatus(data?.requestType, data?.status)
                  : getStatus(data?.requestType, data?.status),
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };

    const _mapperCrossWaitingUnwrapAndSwap = (data: any) => {
        const findChain = chainsDetects.find((chain: any) => chain.chainId === +data?.originNetwork?.chainId);
        const evmNetwork: NetworkTypeDTO = findChain?.chainNetwork || NetworkTypeDTO.polygon;

        const paymentTokenContractAddress =
            data?.paymentToken.contractAddress?.toLowerCase() === nativeETHTokenAddress?.toLowerCase()
                ? contractAddress?.[evmNetwork]?.WNATIVEAddress?.toLowerCase()
                : data?.paymentToken.contractAddress;

        const isTeleBTCToken =
            paymentTokenContractAddress?.toLowerCase() === "0x0000000000000000000000000000000000000000" ||
            paymentTokenContractAddress?.toLowerCase() ===
                contractAddress?.[getValidNetwork(evmNetwork)].teleBTCAddress?.toLowerCase();

        const inputToken = isTeleBTCToken
            ? { symbol: "TELEBTC", decimal: 8 }
            : tokensList?.[evmNetwork].find(
                  (i: any) => i.address?.toLowerCase() === paymentTokenContractAddress?.toLowerCase(),
              );

        const inputAmount = isTeleBTCToken
            ? data?.amount
            : parseCustomTokenValue(
                  data?.details.inputReceeivedAmount || data?.details.inputReceivedAmount || data?.paymentTokenAmount,
                  inputToken?.decimal || 18,
              ); //TODO
        const outputAmount = data?.receivedAmount || "0";
        const fee = setDigitWithFractionMax(+data?.paymentTokenAmountBTC - +data?.receivedAmount);
        const myLastEvent = isCrossNetwork(evmNetwork) ? data?.originEvent : data?.lastEvent;
        const tx = {
            id: data?.id,
            type:
                data?.paymentToken?.contractAddress?.toLowerCase() ===
                contractAddress?.[evmNetwork].teleBTCAddress?.toLowerCase()
                    ? `Unwrap ${data?.status === "Failed" ? "(Failed!)" : ""}`
                    : `Exchange ${data?.status === "Failed" ? "(Failed!)" : ""}`,
            creationTime: new Date(data?.createdAt).getTime() / 1000,
            inputAmount,
            inputToken: inputToken?.symbol,
            inputTokenMain: inputToken,
            inputNetwork: evmNetwork,
            sent: `${inputAmount} ${inputToken?.symbol} on ${evmNetwork} `,
            outputAmount,
            outputToken: "BTC",
            outputTokenMain: {
                address: contractAddress?.[evmNetwork].teleBTCAddress?.toLowerCase(),
                symbol: "TELEBTC",
                decimal: 8,
                icon: "TELEBTC",
                symbolPrice: "BTC",
                type: "btc",
            },
            outputNetwork: "Bitcoin",
            outputAddress: data?.bitcoinAddress || data?.details?.userAddress, //TODO
            received: outputAmount ? `${outputAmount}  BTC on Bitcoin ` : "",
            fee: `${fee} BTC`,
            blockNumber: myLastEvent?.targetTransaction?.blockNumber,
            evmTransactionAddress: `${txEndpoint?.[evmNetwork]}/${myLastEvent?.targetTransaction?.txId}`,
            bitcoinTransactionAddress: `${transactionBTCEndpoint}/${data?.sourceTransaction?.txId}`,
            timestamp: new Date(data?.createdAt).getTime() / 1000,
            status: data?.status?.includes("Failed")
                ? "Failed"
                : getStatus(data?.requestType, data?.status)?.includes("Pending")
                  ? "Pending"
                  : "Pending",
            evmNetwork,
            crosschainId: data?.crosschainId,
        };
        return tx;
    };

    const _mapperRequest = (data: any) => {
        if (data?.requestType === "transfer") {
            return _mapperCCTransfer(data);
        } else if (data?.requestType === "exchange") {
            return _mapperCCExchange(data);
        } else if (data?.requestType === "burn") {
            return _mapperCCBurn(data, "Completed");
        } else {
            return _mapperCCTransfer(data);
        }
    };
    const _mapperRequestPending = (data: any) => {
        if (data?.requestType === "transfer") {
            return _mapperCCTransferPending(data);
        } else if (data?.requestType === "exchange") {
            return _mapperCCExchangePending(data);
        } else if (data?.requestType === "burn") {
            return _mapperCCBurn(data, "Pending");
        } else {
            return _mapperCCTransferPending(data);
        }
    };
    const _mapperRequestWaiting = (data: any) => {
        if (data?.type === "UnwrapAndSwap") {
            return _mapperCrossWaitingUnwrapAndSwap(data);
        }
        return _mapperCrossWaitingUnwrapAndSwap(data);
    };
    const _mapperRequestBrc20 = (data: any) => {
        if (data?.type === "Wrap") {
            return _mapperCCTransferBrc20(data);
        } else if (data?.type === "WrapAndExchange") {
            return _mapperCCExchangeBrc20(data);
        } else if (data?.type === "Unwrap" || data?.type === "ExchangeAndUnwrap" || data?.type === "ExchangeAndBurn") {
            return _mapperCCBurnBrc20(data, "Completed");
        } else {
            return _mapperCCTransferBrc20(data);
        }
    };
    const _mapperRequestBrc20Pending = (data: any) => {
        if (data?.type === "Wrap") {
            return _mapperCCTransferBrc20Pending(data);
        } else if (data?.type === "WrapAndExchange") {
            return _mapperCCExchangeBrc20Pending(data);
        } else if (data?.type === "Unwrap" || data?.type === "ExchangeAndUnwrap" || data?.type === "ExchangeAndBurn") {
            return _mapperCCBurnBrc20(data, "Pending");
        } else {
            return _mapperCCTransferBrc20Pending(data);
        }
    };

    const getCompletedRequests = async (
        networkWallet: string,
        address: string,
        limit = 10,
        cursorDetails: any,
        other: any,
        type: string,
    ) => {
        try {
            let checksumAddresses = [];
            if (networkWallet !== "bitcoin") {
                checksumAddresses = [ethers.utils.getAddress(address)];
            } else {
                if (other && other.length > 0 && address.toLowerCase() === other[0].toLowerCase()) {
                    checksumAddresses = other;
                } else {
                    checksumAddresses = [address];
                }
            }
            const splitAddress = checksumAddresses.join();

            const res: any = await request.get(
                `${baseAPI}${
                    type === "BRC-20"
                        ? `/brc20/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=done&token=brc20&limit=`
                        : type === "RUNE"
                          ? `/brc20/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=done&token=rune&limit=`
                          : `/teleswap/requests/${networkWallet.toLowerCase()}/${splitAddress}?transfer=true&exchange=true&burn=true&limit=`
                }${limit}${
                    cursorDetails?.current && cursorDetails?.current !== "zero" ? `&key=${cursorDetails?.current}` : ""
                }`,
            );

            const requests = (res?.data?.requests || []).map((i: any) => {
                const _i =
                    type === "BRC-20"
                        ? _mapperRequestBrc20(i)
                        : type === "RUNE"
                          ? _mapperRequestBrc20(i)
                          : _mapperRequest(i);
                return _i;
            });

            const listCursor =
                (cursorDetails?.list?.includes(cursorDetails?.current)
                    ? (cursorDetails?.list as []).filter((i, index) => index !== cursorDetails?.list.length - 1)
                    : [...cursorDetails?.list, cursorDetails?.current]) || [];

            const lengthList = listCursor.length;

            const item = {
                previousCursor: lengthList > 1 ? listCursor[lengthList - 2] : lengthList > 0 ? null : "zero",
                data: requests,
                total: res?.data?.totalRequests || 0,
                nextCursor:
                    limit * lengthList < res?.data?.totalRequests && res?.key && res?.key !== "0:0:0" ? res?.key : null,
                currentCursor: cursorDetails?.current,
                listCursor: listCursor,
            };

            return item;
        } catch (error) {
            return null;
        }
    };
    const getPendingRequests = async (
        networkWallet: string,
        address: string,
        limit = 10,
        cursorDetails: any,
        other: any,
        type: string,
    ) => {
        try {
            let checksumAddresses = [];
            if (networkWallet !== "bitcoin") {
                checksumAddresses = [ethers.utils.getAddress(address)];
            } else {
                if (other && other.length > 0 && address.toLowerCase() === other[0].toLowerCase()) {
                    checksumAddresses = other;
                } else {
                    checksumAddresses = [address];
                }
            }
            const splitAddress = checksumAddresses.join();

            const res: any = await request.get(
                `${baseAPI}${
                    type === "BRC-20"
                        ? `/brc20/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=pending&token=brc20&limit=`
                        : type === "RUNE"
                          ? `/brc20/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=pending&token=rune&limit=`
                          : `/teleswap/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=pending&transfer=true&exchange=true&burn=true&limit=`
                }${limit}${
                    cursorDetails?.current && cursorDetails?.current !== "zero" ? `&key=${cursorDetails?.current}` : ""
                }`,
            );

            const requests = (res?.data?.requests || []).map((i: any) => {
                const _i =
                    type === "BRC-20"
                        ? _mapperRequestBrc20Pending(i)
                        : type === "RUNE"
                          ? _mapperRequestBrc20Pending(i)
                          : _mapperRequestPending(i);
                return _i;
            });

            const listCursor =
                (cursorDetails?.list?.includes(cursorDetails?.current)
                    ? (cursorDetails?.list as []).filter((i, index) => index !== cursorDetails?.list.length - 1)
                    : [...cursorDetails?.list, cursorDetails?.current]) || [];

            const lengthList = listCursor.length;

            const waitingResult =
                type === "BRC-20" || type === "RUNE"
                    ? { data: [] }
                    : await getWaitingRequests(networkWallet, address, 10, { current: "zero", list: [] }, other, type);

            const item = {
                previousCursor: lengthList > 1 ? listCursor[lengthList - 2] : lengthList > 0 ? null : "zero",
                data: [...waitingResult?.data, ...requests],
                total: res?.data?.totalRequests || 0,
                nextCursor:
                    limit * lengthList < res?.data?.totalRequests && res?.key && res?.key !== "0:0:0" ? res?.key : null,
                currentCursor: cursorDetails?.current,
                listCursor: listCursor,
            };

            return item;
        } catch (error) {
            return null;
        }
    };
    const getAllRequests = async (
        networkWallet: string,
        address: string,
        limit = 10,
        cursorDetails: any,
        other: any,
        type: string,
    ) => {
        try {
            let checksumAddresses = [];
            if (networkWallet !== "bitcoin") {
                checksumAddresses = [ethers.utils.getAddress(address)];
            } else {
                if (other && other.length > 0 && address.toLowerCase() === other[0].toLowerCase()) {
                    checksumAddresses = other;
                } else {
                    checksumAddresses = [address];
                }
            }
            const splitAddress = checksumAddresses.join();

            const res: any = await request.get(
                `${baseAPI}${
                    type === "BRC-20"
                        ? `/brc20/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=all&token=brc20&limit=`
                        : type === "RUNE"
                          ? `/brc20/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=all&token=rune&limit=`
                          : `/teleswap/requests/${networkWallet.toLowerCase()}/${splitAddress}?filter=all&transfer=true&exchange=true&burn=true&limit=`
                }${limit}${
                    cursorDetails?.current && cursorDetails?.current !== "zero" ? `&key=${cursorDetails?.current}` : ""
                }`,
            );

            const requestsPending = (res?.data?.requests || [])
                .filter(
                    (i: any) =>
                        (i.requestType === "burn" && i.status === "NotProcessed") ||
                        (i.requestType !== "burn" && (i.status === "Pending" || i.status === "Confirmed")),
                )
                .map((i: any) => {
                    const _i =
                        type === "BRC-20"
                            ? _mapperRequestBrc20Pending(i)
                            : type === "RUNE"
                              ? _mapperRequestBrc20Pending(i)
                              : _mapperRequestPending(i);
                    return _i;
                });
            const requestsCompleted = (res?.data?.requests || [])
                .filter(
                    (i: any) =>
                        (i.requestType === "burn" &&
                            (i.status === "Submitted" || i.status === "Pending" || i.status === "Confirmed")) ||
                        (i.requestType !== "burn" && (i.status === "Submitted" || i.status === "Failed")),
                )
                .map((i: any) => {
                    const _i =
                        type === "BRC-20"
                            ? _mapperRequestBrc20(i)
                            : type === "RUNE"
                              ? _mapperRequestBrc20(i)
                              : _mapperRequest(i);
                    return _i;
                });

            const listCursor =
                (cursorDetails?.list?.includes(cursorDetails?.current)
                    ? (cursorDetails?.list as []).filter((i, index) => index !== cursorDetails?.list.length - 1)
                    : [...cursorDetails?.list, cursorDetails?.current]) || [];

            const lengthList = listCursor.length;

            const waitingResult =
                type === "BRC-20" || type === "RUNE"
                    ? { data: [] }
                    : await getWaitingRequests(networkWallet, address, 10, { current: "zero", list: [] }, other, type);

            const item = {
                previousCursor: lengthList > 1 ? listCursor[lengthList - 2] : lengthList > 0 ? null : "zero",
                data: [...waitingResult?.data, ...requestsPending, ...requestsCompleted],
                total: res?.data?.totalRequests || 0,
                nextCursor:
                    limit * lengthList < res?.data?.totalRequests && res?.key && res?.key !== "0:0:0" ? res?.key : null,
                currentCursor: cursorDetails?.current,
                listCursor: listCursor,
            };

            return item;
        } catch (error) {
            return null;
        }
    };
    const getWaitingRequests = async (
        networkWallet: string,
        address: string,
        limit = 10,
        cursorDetails: any,
        other: any,
        type: string,
    ) => {
        try {
            let checksumAddresses = [];
            if (networkWallet !== "bitcoin") {
                checksumAddresses = [ethers.utils.getAddress(address)];
            } else {
                if (other && other.length > 0 && address.toLowerCase() === other[0].toLowerCase()) {
                    checksumAddresses = other;
                } else {
                    checksumAddresses = [address];
                }
            }
            const splitAddress = checksumAddresses.join();

            const res: any = await request.get(
                `${baseAPI}${`/teleswap/cross-chain/waiting-request/${splitAddress}?filter=all&limit=`}${limit}${
                    cursorDetails?.current && cursorDetails?.current !== "zero" ? `&key=${cursorDetails?.current}` : ""
                }`,
            );

            const requests = (res?.data || []).map((i: any) => {
                const _i = _mapperRequestWaiting(i);
                return _i;
            });

            const listCursor =
                (cursorDetails?.list?.includes(cursorDetails?.current)
                    ? (cursorDetails?.list as []).filter((i, index) => index !== cursorDetails?.list.length - 1)
                    : [...cursorDetails?.list, cursorDetails?.current]) || [];

            const lengthList = listCursor.length;

            const item = {
                previousCursor: lengthList > 1 ? listCursor[lengthList - 2] : lengthList > 0 ? null : "zero",
                data: requests,
                total: res?.total || 0,
                nextCursor: limit * lengthList < res?.total && res?.key && res?.key !== "0:0:0" ? res?.key : null,
                currentCursor: cursorDetails?.current,
                listCursor: listCursor,
            };

            return item;
        } catch (error) {
            return null;
        }
    };

    const getAllCompletedRequests = async (limit = 10, cursorDetails: any, type: string) => {
        try {
            const res: any = await request.get(
                `${baseAPI}${
                    type === "BRC-20"
                        ? `/brc20/requests?filter=done&token=brc20&limit=`
                        : type === "RUNE"
                          ? `/brc20/requests?filter=done&token=rune&limit=`
                          : "/teleswap/requests?transfer=true&exchange=true&burn=true&limit="
                }${limit}${
                    cursorDetails?.current && cursorDetails?.current !== "zero" ? `&key=${cursorDetails?.current}` : ""
                }`,
            );
            const requests = (res?.data?.requests || []).map((i: any) => {
                const _i =
                    type === "BRC-20"
                        ? _mapperRequestBrc20(i)
                        : type === "RUNE"
                          ? _mapperRequestBrc20(i)
                          : _mapperRequest(i);
                return _i;
            });

            const listCursor =
                (cursorDetails?.list?.includes(cursorDetails?.current)
                    ? (cursorDetails?.list as []).filter((i, index) => index !== cursorDetails?.list.length - 1)
                    : [...cursorDetails?.list, cursorDetails?.current]) || [];

            const lengthList = listCursor.length;

            const item = {
                previousCursor: lengthList > 1 ? listCursor[lengthList - 2] : lengthList > 0 ? null : "zero",
                data: requests,
                total: res?.data?.totalRequests || 0,
                nextCursor:
                    limit * lengthList < res?.data?.totalRequests && res?.key && res?.key !== "0:0:0" ? res?.key : null,
                currentCursor: cursorDetails?.current,
                listCursor: listCursor,
            };

            return item;
        } catch (error) {
            return null;
        }
    };

    const submitEmailAndQuestions = async (data: any): Promise<any> => {
        await request.post(`${baseAPI}/general/survey/2/submit`, data);
    };
    const getEmailAndQuestions = async (): Promise<any> => {
        if (account) {
            const address = account ? ethers.utils.getAddress(account) : "";
            const resSurvey: any = await request.get(`${baseAPI}/general/survey/2/response?address=${address}`);
            return resSurvey?.data;
        }
        return { id: "" };
    };

    const _mapperFAQ = (faq: any): any => {
        const _faq: any = {
            key: faq.id,
            title: faq.question,
            desc: faq.description,
            order: faq.order,
        };
        return _faq;
    };
    const _mapperFAQs = (faqs: any): any => {
        const _faqs: any[] = (faqs || []).map((faq: any) => {
            const _faq = _mapperFAQ(faq);
            return _faq;
        });
        return sortBy(_faqs, "order", "asc");
    };
    const getFAQsByType = async (category: string): Promise<any> => {
        const data: any = await request.get(`${baseAPI}/general/faq/teleswap/${category}`);
        const faqs: any = _mapperFAQs(data?.data || []);
        return faqs;
    };
    const getFAQsCategories = async (): Promise<any> => {
        try {
            const data: any = await request.get(`${baseAPI}/general/faq-categories/teleswap`);
            return data?.data;
        } catch (error) {
            return ["General", "Bridging", "Trading"];
        }
    };

    const getEnableStats = async (): Promise<any> => {
        try {
            const data: any = await request.get(`${baseAPI}/general/enable-stats/teleswap`);
            return data?.data;
        } catch (error) {
            return {
                brc20Wrap: true,
                btcWrap: true,
                brc20Unwrap: true,
                btcUnwrap: true,
                brc20WrapSwap: true,
                btcWrapSwap: true,
                brc20SwapUnwrap: true,
                btcSwapUnwrap: true,
            };
        }
    };

    const getAllTokenBalances = async (account: string) => {
        const address = account ? ethers.utils.getAddress(account) : "";

        const result: any = await request.get(`${baseAPI}/proxy/all-token-balances/${address}`);
        return result?.data;
    };
    return {
        getAllTokenBalances,
        getEnableStats,
        getFAQsByType,
        getFAQsCategories,
        getInfo,
        getVolume,
        getTVl,
        getLockerDetails,
        getLockers,
        getTeleswapDetails,
        getTeleporters,
        getRelayersInfo,
        getRelayers,
        getBlocks,
        getCompletedRequests,
        getPendingRequests,
        getAllRequests,
        getWaitingRequests,
        getAllCompletedRequests,
        submitEmailAndQuestions,
        getEmailAndQuestions,
        getSupportedBrc20TokensExchange,
        getSupportedBrc20TokensFarming,
    };
};
