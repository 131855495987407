import Image from "ui-components/Image";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { projectName } from "utils/configs";

export default function PromotionSlider() {
    return (
        <div
            className={classNames(
                "flex justify-center items-center  px-4  md:p-0 md:pb-4  md:max-w-[1000px]  bg-no-repeat bg-center rounded-[0.625rem] md:rounded-[1.875rem] w-full  h-auto",
            )}
        >
            <NavLink
                className="flex items-center min-h-[92px] text-primary pt-0 w-full"
                // target="_blank"
                to={projectName === "investor" ? "https://app.teleswap.xyz" : "/staking"}
            >
                <Image
                    src={"/images/banner-q.png"}
                    alt="Poster"
                    loading="lazy"
                    className="object-fill object-center  w-full rounded-[0.3rem] md:rounded-[0.625rem] !relative"
                />
            </NavLink>
        </div>
    );
}
