/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import Image from "ui-components/Image";
import { dAppConfigMetaData } from "utils/configs";

const MenuList = () => {
    const Link = ({ children, href, className = "", target = "", disabled = false, activeClassName }: any) => {
        return target === "_blank" ? (
            <a
                href={href}
                className={`${className} ${disabled ? "disabled" : ""}`}
                target={target}
                rel="noopener noreferrer"
            >
                {children}
            </a>
        ) : (
            <NavLink
                to={href}
                replace={false}
                // className={`${className} ${disabled ? "disabled" : ""}`}
                target={target}
                className={({ isActive }) =>
                    isActive
                        ? `${className} ${disabled ? "disabled" : ""} ${activeClassName}`
                        : `${className} ${disabled ? "disabled" : ""} `
                }
                rel="noopener noreferrer"
            >
                {children}
            </NavLink>
        );
    };

    return (
        <Popover className="relative">
            {({ open: _open, close }) => (
                <>
                    <Popover.Button className="focus:outline-none flex items-center gap-1 -mt-8 md:mt-0 mr-3 md:mr-0  bg-ground-lighter  rounded p-1.5 md:p-3 min-w-[20px]  md:min-w-[30px]">
                        <EllipsisVerticalIcon className="font-medium text-white text-xs w-6 md:w-6  " />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute right-1 lg:right-1  top-2 lg:top-12 max-w-[210px] z-10 mt-3 w-screen  ">
                            {_open && (
                                <>
                                    <div
                                        onClick={close}
                                        className="overflow-hidden   bg-ground-dark border border-ground_border-lighter rounded-lg  ring-opacity-5"
                                    >
                                        <div className="relative flex flex-col  w-full py-1">
                                            <Link
                                                href={dAppConfigMetaData.twitterUrl}
                                                target={"_blank"}
                                                className="text-sm gap-2 text-white  hover:bg-ground-lighter flex p-3  "
                                            >
                                                <Image
                                                    src={`/images/XLogo.svg`}
                                                    width="20"
                                                    height="20"
                                                    alt={"discord"}
                                                    loading="lazy"
                                                />{" "}
                                                Follow X
                                            </Link>
                                            <Link
                                                href={dAppConfigMetaData.discordUrl}
                                                target={"_blank"}
                                                className="text-sm gap-2 text-white  hover:bg-ground-lighter flex p-3 font-medium "
                                            >
                                                <Image
                                                    src={`/images/DiscordLogo.svg`}
                                                    width="20"
                                                    height="20"
                                                    alt={"discord"}
                                                    loading="lazy"
                                                />{" "}
                                                Join Discord
                                            </Link>

                                            <Link
                                                href={dAppConfigMetaData.docUrl}
                                                target={"_blank"}
                                                className="text-sm gap-2 text-white   hover:bg-ground-lighter flex p-3 font-medium "
                                            >
                                                <Image
                                                    src={`/images/github-icon.svg`}
                                                    width="20"
                                                    height="20"
                                                    alt={"discord"}
                                                    loading="lazy"
                                                />{" "}
                                                Read docs
                                            </Link>
                                            <Link
                                                href={dAppConfigMetaData.blogUrl}
                                                target={"_blank"}
                                                className="text-sm gap-2 text-white   hover:bg-ground-lighter flex p-3 font-medium "
                                            >
                                                <Image
                                                    src={`/images/medium.svg`}
                                                    width="20"
                                                    height="20"
                                                    alt={"discord"}
                                                    loading="lazy"
                                                />{" "}
                                                Read blog
                                            </Link>
                                            {/* <Link
                                                href={dAppConfigMetaData.mailUrl}
                                                target={"_blank"}
                                                className="text-sm gap-2 text-white   hover:bg-ground-lighter flex p-3 font-medium "
                                            >
                                                <Image
                                                    src={`/images/Lifebuoy.svg`}
                                                    width="20"
                                                    height="20"
                                                    alt={"discord"}
                                                    loading="lazy"
                                                />{" "}
                                                Email us
                                            </Link> */}
                                            <Link
                                                href={dAppConfigMetaData.tstUrl}
                                                target={"_blank"}
                                                className="text-sm gap-2 text-white   hover:bg-ground-lighter flex p-3 font-medium "
                                            >
                                                <Image
                                                    src={`/images/tst.png`}
                                                    width="20"
                                                    height="20"
                                                    alt={"discord"}
                                                    loading="lazy"
                                                />{" "}
                                                Teleport System Token
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};
export default MenuList;
